import { Box, Grid, Stack, Typography } from '@mui/material'
import TempPic from 'assets/images/team-pic-temp1.png'
import TempPic2 from 'assets/images/team-pic-temp2.png'
import { Tag } from '../web3/jobboard/JobBoard'
import Bg from 'assets/images/team-picture-bg.jpg'
import { ReactComponent as Comment } from 'assets/svg/comment.svg'
import { ReactComponent as Fans } from 'assets/svg/fans.svg'

interface FanCardData {
  pic: string
  tag: string
  title: string
  tags: string[]
}

function FanCard({ data }: { data: FanCardData }) {
  return (
    <Box
      style={{
        border: '4px solid #000000',
        borderRadius: '20px',
        position: 'relative'
      }}
    >
      <img src={data.pic} width={'100%'} height={'auto'} style={{ borderRadius: '20px 20px 0 0' }} />
      <Box style={{ position: 'absolute', top: '20px', right: '20px' }}>
        <Tag title={data.tag} idx={0} />
      </Box>
      <Box
        style={{
          background: '#626262',
          borderRadius: '0 0 20px 20px',
          padding: '25px'
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography style={{ fontSize: '24px' }}>{data.title}</Typography>
          <Stack direction={'row'} spacing={20}>
            {data.tags.map((tag, index) => {
              return (
                <Typography style={{ fontSize: '18px' }} key={index}>
                  {tag}
                </Typography>
              )
            })}
          </Stack>
        </Box>
        <Box
          style={{
            display: 'flex',
            marginTop: '20px',
            justifyContent: 'space-between'
          }}
        >
          <Comment style={{ height: '22px', width: 'auto' }} />
          <Fans style={{ height: '22px', width: 'auto' }} />
          <Comment style={{ height: '22px', width: 'auto' }} />
        </Box>
      </Box>
    </Box>
  )
}

export default function TeamPic() {
  const fakeData: FanCardData[] = [
    {
      pic: TempPic,
      title: 'Title',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    },
    {
      pic: TempPic2,
      title: 'Title',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    },
    {
      pic: TempPic,
      title: 'Title',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    },
    {
      pic: TempPic2,
      title: 'Title',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    },
    {
      pic: TempPic,
      title: 'Title',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    },
    {
      pic: TempPic2,
      title: 'Title',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    }
  ]
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        backgroundImage: `url(${Bg})`,
        backgroundSize: 'cover'
      }}
    >
      <Typography style={{ color: 'white', fontSize: '60px', width: '54%', textAlign: 'center', marginTop: '30px' }}>
        #DWG LOUNGE LOL TEAM PICTURE
      </Typography>
      <Stack direction={'row'}>
        <Tag title={'#DWG'} idx={0} />
        <Tag title={'#LOL'} idx={0} />
      </Stack>
      <Grid padding={130} spacing={40} container width={'100%'}>
        {fakeData.map((data, index) => {
          return (
            <Grid item key={index} md={4}>
              <FanCard data={data} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
