import { Box } from '@mui/material'
import { SwiperBox } from 'pages/QuestLounge'
import { ReactComponent as Right } from '../../assets/svg/arrow-right.svg'
import { ReactComponent as Left } from '../../assets/svg/arrow-left.svg'
import { useEffect, useState } from 'react'
import memberDefault from 'assets/images/lol/member_default.png'

export default function MembersSwiper() {
  const [swiper, setSwiper] = useState<any>(null)

  useEffect(() => {
    const _s = new window.Swiper('.swiper-container.two', {
      effect: 'coverflow',
      loop: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      coverflow: {
        rotate: 0,
        stretch: 100,
        depth: 150,
        modifier: 1.5,
        slideShadows: false
      },
      onInit: function(s: any) {
        setTimeout(s.slideNext, 1000)
      }
    })
    setSwiper(_s)
  }, [])

  return (
    <SwiperBox padding="0.5rem 1rem">
      <div className="swiper-container two">
        <div className="swiper-wrapper">
          {[1, 2, 3, 4, 5, 6].map((item, index) => (
            <div className="swiper-slide" key={index}>
              <div className="slider-image">
                <img src={memberDefault} alt="" />
              </div>
            </div>
          ))}
        </div>
        <div className="swiper-pagination"></div>
      </div>
      <Box>
        <Left className="prve" onClick={() => swiper?.slidePrev()} />
        <Right className="next" onClick={() => swiper?.slideNext()} />
      </Box>
    </SwiperBox>
  )
}
