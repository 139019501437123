import Bg from '../../assets/images/bg_campaign.jpg'
import { Box, Stack, Typography } from '@mui/material'
import Divider from '../../components/Divider'
import { CompaignsCard } from './AllCompaigns'
import { GreenButton } from './CommunitySetting'
import { MenuImageButton } from '../../components/SecondaryMenu'
import Checkbox from 'components/Checkbox'
import { Tag } from '../web3/jobboard/JobBoard'
import { ReactComponent as SocialIcon } from '../../assets/svg/social-media-icons.svg'

function WhiteBoard() {
  return (
    <Box
      style={{
        color: 'black',
        background: 'white',
        border: '1px solid black',
        height: 'fit-content',
        alignSelf: 'center',
        padding: '30px 50px'
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'baseline'
        }}
      >
        <Typography fontSize={40} fontWeight={500} fontFamily={'SCDreamBlack'}>
          73
        </Typography>
        <Typography fontSize={30} fontFamily={'SCDreamMedium'}>
          DPLUS POINTS
        </Typography>
      </Box>
      <Typography fontSize={30} mt={20}>
        TYPE: LUCKY DRAW
      </Typography>
    </Box>
  )
}

export default function Campaign() {
  const fakeTask = ['Follow @hadahada on Twitter', 'Follow @hadahada on Discord', 'Follow @hadahada on Instagram']
  return (
    <Box
      style={{
        background: `url(${Bg})`,
        display: 'flex',
        width: '100%',
        backgroundRepeat: 'repeat',
        flexDirection: 'column',
        padding: '120px'
      }}
    >
      <Typography fontSize={60} fontFamily={'SCDreamExtraBold'}>
        CAMPAIGN NAME
      </Typography>
      <Divider />
      <Box display={'flex'} mt={50}>
        <Box width={500}>
          <CompaignsCard />
        </Box>
        <Box ml={55}>
          <GreenButton>CREATOR</GreenButton>
          <Typography fontSize={40} mt={35} fontWeight={500} fontFamily={'SCDreamMedium'}>
            #Ongoing
          </Typography>
          <Typography fontSize={30} mt={30}>
            2022.12.31 00:00:00 ~ 2023.01.31 00:00:00
          </Typography>
          <Typography fontSize={60} mt={70} fontWeight={500} fontFamily={'SCDreamExtraBold'}>
            28
          </Typography>
          <Typography fontSize={40} mt={20}>
            Participants Joined
          </Typography>
        </Box>
      </Box>
      <Divider style={{ margin: '50px 0' }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography fontSize={40} marginBottom={50}>
            DESCRIPTION
          </Typography>
          <Typography fontSize={30}>
            Description Sample Text…Description Sample Text…
            <br /> Description Sample Text…
            <br />
            Description Sample Text… <br />
            Description Sample Text…Description Sample Text…
          </Typography>
        </Box>
        <WhiteBoard />
      </Box>
      <Divider style={{ marginTop: '80px' }} />
      <Box sx={{ alignSelf: 'flex-end', marginTop: '-30px' }}>
        <MenuImageButton>CLAIM</MenuImageButton>
      </Box>
      <Typography fontSize={40} fontFamily={'SCDreamMedium'}>
        Task
      </Typography>
      <Stack spacing={10}>
        {fakeTask.map((task, index) => {
          return <Checkbox key={index} label={task} />
        })}
      </Stack>
      <Divider style={{ margin: '50px 0' }} />
      <Box display={'flex'}>
        <Box width={500}>
          <CompaignsCard />
        </Box>
        <Box marginLeft={30} display={'flex'} justifyContent={'space-between'} flexDirection={'column'}>
          <Box>
            <Typography fontSize={40}>ABOUT COMMUNITY</Typography>
            <Typography fontSize={20} mt={30}>
              Description Sample Text…Description Sample Text…
              <br /> Description Sample Text…
              <br />
              Description Sample Text… <br />
              Description Sample Text…Description Sample Text…
            </Typography>
          </Box>
          <Box>
            <Box mb={30}>
              <Tag idx={0} title={'+ FOLLOW'} />
            </Box>
            <SocialIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
