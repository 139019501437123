import { Box, Typography } from '@mui/material'
import { ImgHTMLAttributes, useMemo, useState } from 'react'

const BAD_SRCS: { [tokenAddress: string]: true } = {}

export default function Image({
  src,
  alt = '',
  style,
  className,
  altSrc,
  ...rest
}: {
  src: string
  alt?: string
  style?: React.CSSProperties
  className?: string
  altSrc?: string
} & Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt' | 'style'>) {
  const [, refresh] = useState<number>(0)
  const srcs = useMemo(() => [src, altSrc], [src, altSrc])
  const srcStr = srcs.find(item => !BAD_SRCS[item ?? ''])
  return (
    <img
      {...rest}
      src={srcStr}
      alt={alt}
      style={style}
      className={className}
      onError={() => {
        if (srcStr) BAD_SRCS[srcStr] = true
        refresh(i => i + 1)
      }}
    />
  )
}

export function GalleryImage({ src, width, height }: { src?: string; width?: string; height?: string }) {
  return (
    <Box
      sx={{
        width: width || '3.5rem',
        height: height || '3.5rem',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {src ? (
        <Image src={src} style={{ width: '100%', height: '100%' }} />
      ) : (
        <Typography color={'#000'} fontSize="0.2rem">
          Image Preview
        </Typography>
      )}
    </Box>
  )
}
