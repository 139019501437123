import { Box, Stack, Typography } from '@mui/material'
import { Context } from './DWGLounge'
import bg21 from '../../assets/images/lounge/bg_21.png'
import TeamLounge from '../../assets/images/lounge/lounge-banner1.jpg'
import QuestLounge from '../../assets/images/lounge/lounge-banner2.jpg'
import { MenuImageButton } from 'components/SecondaryMenu'
import Image from 'components/Image'
// import { useHistory } from 'react-router-dom'
// import { routes } from 'constants/routes'

export default function Lounge() {
  // const history = useHistory()
  return (
    <Context
      sx={{
        minHeight: '100vh',
        backgroundImage: `url(${bg21})`,
        backgroundSize: '100% auto'
      }}
    >
      <Box
        sx={{
          width: '100%',
          top: '0.69rem',
          padding: '0 1rem',
          position: 'absolute'
        }}
      >
        <Stack justifyContent={'center'} spacing="0.2rem" direction={'row'}>
          <Card
            src={TeamLounge}
            title="FAN LOUNGE"
            // btnEvent={() => history.push(routes.DWGLounge)}
            btnEvent={() => {}}
            btnText={'COMING SOON'}
            // btnText={'DISCOVER MORE'}
          />
          <Card
            src={QuestLounge}
            title="GAMER LOUNGE"
            // btnEvent={() => history.push(routes.questLounge)}
            btnEvent={() => {}}
            btnText={'COMING SOON'}
          />
        </Stack>
      </Box>
    </Context>
  )
}

function Card({
  src,
  title,
  btnEvent,
  btnText
}: {
  src: string
  title: string
  btnEvent: () => void
  btnText: string
}) {
  return (
    <Box display={'grid'} justifyItems="center" gap="0.2rem">
      <Typography fontSize={'0.4rem'} fontWeight="bold" fontFamily={'SCDreamExtraBold'}>
        {title}
      </Typography>
      <Image
        src={src}
        style={{
          width: '6rem'
        }}
      />
      <MenuImageButton
        style={{
          marginTop: '-0.7rem'
        }}
        onClick={btnEvent}
      >
        {btnText}
      </MenuImageButton>
    </Box>
  )
}
