import { Box, Grid, Typography } from '@mui/material'
import { DividerLine, WhiteText } from '../onboarding/Onboarding'
import BgTop from 'assets/images/job-board-bg-top.png'
import BgBottom from 'assets/images/job-board-bg-bottom.png'
import { JobCard } from './JobBoardCard'
import { MenuImageButton } from '../../../components/SecondaryMenu'
import { scrollToElement } from 'utils'
import { useHistory } from 'react-router-dom'
import { routes } from 'constants/routes'
import OnboardingCard from '../onboarding/OnboardingCard'

import Temp1 from 'assets/images/cm_1.png'
import Temp2 from 'assets/images/dev_1.png'
import AcademyTemp1 from 'assets/images/dev_2.png'
import AcademyTemp2 from 'assets/images/dev_3.png'

export function Tag({ idx, title }: { idx: number; title: string }) {
  return (
    <Typography
      onClick={() => scrollToElement(idx < 4 ? 'tag0' : 'tag3')}
      sx={{
        backgroundColor: idx % 2 == 0 ? '#9CF8EE' : '#30F9C0',
        padding: '0.12rem 0.28rem',
        cursor: 'pointer',
        color: 'black',
        fontSize: '0.3rem',
        fontWeight: '600',
        fontFamily: 'SCDreamMedium',
        margin: '0.1rem',
        textAlign: 'center',
        minWidth: '2.4rem',
        width: 'fit-content',
        borderRadius: '22px'
      }}
    >
      {title}
    </Typography>
  )
}

export default function JobBoard() {
  const history = useHistory()
  const dummyTag = ['#HIRING', '#SEEKING', '#CM', '#PM', '#DEV', '#BUSINESS', '#OPERATION']
  const dummyCardList: JobCard[] = [
    {
      tag: ['#HIRING', '#SEEKING'],
      isBeginner: true,
      title: 'HIRING A NEW CM FOR DRB',
      pic: Temp1
    },
    {
      tag: ['#CM', '#PM'],
      isBeginner: false,
      title: 'SEEKING A PM FOR GHOST PROJECT',
      pic: Temp2
    },
    {
      tag: ['#DEV', '#BUSINESS'],
      isBeginner: true,
      title: 'HIRING A NEW CM FOR DRB',
      pic: AcademyTemp1
    },
    {
      tag: ['#OPERATION'],
      isBeginner: false,
      title: 'SEEKING A PM FOR GHOST PROJECT',
      pic: AcademyTemp2
    }
  ]
  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box position={'relative'} width={'100%'} display={'flex'} justifyContent={'center'}>
        <img src={BgTop} style={{ width: '100%' }} />
        <Box
          mt={'2.3rem'}
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          position={'absolute'}
          alignItems={'center'}
        >
          <WhiteText fontSize={'1rem'} fontWeight={600}>
            START AT Dplus Arena Academy
          </WhiteText>
          <DividerLine />
          <WhiteText fontSize={'0.3rem'} mt={'0.58rem'}>
            Hire & Seek the latest positions in Web3
          </WhiteText>
          <MenuImageButton sx={{ marginTop: '45px' }} onClick={() => history.push(routes.job_boardCreate)}>
            CREATE
          </MenuImageButton>
          <Box mt={37} display={'flex'} width={'60%'} sx={{ flexFlow: 'wrap', justifyContent: 'center' }}>
            {dummyTag.map((title, index) => {
              return <Tag key={index} idx={index} title={title} />
            })}
          </Box>
        </Box>
      </Box>
      <Box position={'relative'} width={'100%'} display={'flex'} justifyContent={'center'}>
        <img src={BgBottom} style={{ width: '100%' }} />
        <Box width={'100%'} display={'flex'} flexDirection={'column'} position={'absolute'} alignItems={'center'}>
          <Grid container spacing={50} mt={'0.9rem'} width={'88%'}>
            {dummyCardList.map((card, index) => {
              return (
                <Grid key={index} item xs={6} id={`tag${index}`}>
                  <OnboardingCard
                    click={() => history.push(routes.job_boardDetail)}
                    title={card.title}
                    tag={card.tag}
                    pic={card.pic}
                    isBeginner={card.isBeginner}
                    key={index}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}
