import { Box, Stack, styled, Typography } from '@mui/material'
import Bg from 'assets/images/bg_seeking_create.jpg'
import Button from 'components/Button/Button'
import { GalleryImage } from 'components/Image'
import Divider from '../../../components/Divider'
import { MenuImageButton } from '../../../components/SecondaryMenu'

const SubTitle = styled(Typography)`
  margin-top: 1rem;
  font-family: 'SCDreamMedium', serif;
  font-size: 0.3rem;
`

const SubText = styled(Typography)`
  margin-top: 0.1rem;
  font-size: 0.25rem;
`

const MarginDivider = styled(Divider)`
  margin-top: 1rem;
`

export default function JobCardDetail() {
  return (
    <Box
      style={{
        backgroundImage: `url(${Bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        width: '100%',
        padding: '100px',
        minHeight: '100vh'
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ width: '100%' }}>
          <Typography fontSize={'0.4rem'} fontFamily={'SCDreamExtraBold'}>
            [TITLE] HIRING A NEW CM FOR DRB
          </Typography>
          <Divider />
        </Box>
        <Stack spacing="0.3rem" direction={'row'}>
          <Button
            width="2.4rem"
            height="0.88rem"
            style={{ borderRadius: '0.44rem', fontWeight: 500, background: '#30F9C0', color: '#000' }}
          >
            #HIRING
          </Button>
          <Button
            width="2.4rem"
            height="0.88rem"
            style={{ borderRadius: '0.44rem', fontWeight: 500, background: '#30F9C0', color: '#000' }}
          >
            #CM
          </Button>
        </Stack>
      </Box>

      <SubTitle>WORK EXPERIENCE</SubTitle>
      <SubText>
        Description Sample Text…Description Sample Text…Description Sample Text…Description Sample Text…
      </SubText>
      <SubText>
        Description Sample Text…Description Sample Text…Description Sample Text…Description Sample Text…
      </SubText>

      <MarginDivider />
      <SubTitle>QUALIFICATIONS</SubTitle>
      <SubText>
        Description Sample Text…Description Sample Text…Description Sample Text…Description Sample Text…
      </SubText>
      <SubText>
        Description Sample Text…Description Sample Text…Description Sample Text…Description Sample Text…
      </SubText>

      <MarginDivider />

      <Box display={'flex'} justifyContent="space-between" mt="1rem" mb="0.8rem">
        {[1, 2, 3].map(v => (
          <GalleryImage width="5.3rem" height="5.3rem" key={v} />
        ))}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '0.4rem', position: 'relative' }}>
        <Divider sx={{ width: '100%' }} />
        <MenuImageButton sx={{ minWidth: '200px', position: 'absolute', right: '0px' }}>APPLY</MenuImageButton>
      </Box>
    </Box>
  )
}
