import { Box, Input, Stack, styled } from '@mui/material'
import Bg from 'assets/images/email-registration-bg.jpg'
import { ReactComponent as Logo } from 'assets/images/logo-black.svg'
import { useState } from 'react'
import { routes } from '../../constants/routes'
import { useHistory } from 'react-router-dom'
import { SecondaryMenuColumn } from '../../components/SecondaryMenu'
import { DividerLine, WhiteText } from '../web3/onboarding/Onboarding'
import { Tag } from '../web3/jobboard/JobBoard'

const BlackSolidInput = styled(Input)`
  border: 2px solid #000000;
  background-color: white;
  font-size: 0.2rem;
  color: black;
  padding: 0.2rem 0.3rem;
`
const Title = styled(WhiteText)`
  font-size: 1rem;
`
const TitleGreen = styled(WhiteText)`
  font-size: 1rem;
  font-family: 'SCDreamExtraBold', serif;
  color: #9cf8ee;
`
const Underline = styled(DividerLine)`
  margin-top: -20px;
`
const UnderlineGreen = styled(Underline)`
  border: 1px solid #9cf8ee;
`

export default function RegistrationByEmail() {
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const history = useHistory()
  return (
    <Box position={'relative'} width={'100%'}>
      <img src={Bg} style={{ width: '100%', position: 'absolute' }} />
      <Stack width={'100%'} sx={{ margin: '0.4rem 0.5rem' }} direction={'column'} style={{ position: 'absolute' }}>
        <Title>WELCOME</Title>
        <Underline />
        <Title>TO THE</Title>
        <Underline sx={{ width: '30%' }} />
        <Title>DWGG</Title>
        <Underline sx={{ width: '30%' }} />
        <Title>WORLD</Title>
        <Underline sx={{ width: '25%', marginLeft: '-50px' }} />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          marginTop: '127px',
          position: 'absolute'
        }}
      >
        <Logo />
        <Stack
          display={'flex'}
          alignItems={'center'}
          spacing={30}
          sx={{ mt: '57px' }}
          direction={'column'}
          width={'40%'}
        >
          <BlackSolidInput fullWidth placeholder={'Email'} value={email} onChange={e => setEmail(e.target.value)} />
          <BlackSolidInput fullWidth placeholder={'Password'} value={pwd} onChange={e => setPwd(e.target.value)} />
          <BlackSolidInput
            fullWidth
            placeholder={'PASSWORD CHECK'}
            value={pwd}
            onChange={e => setPwd(e.target.value)}
          />
          <BlackSolidInput fullWidth placeholder={'REFERAL CODE'} value={pwd} onChange={e => setPwd(e.target.value)} />
          <SecondaryMenuColumn list={[{ text: 'Join', event: () => history.push(routes.questLoungeMyCommunity) }]} />
        </Stack>
        <Box style={{ position: 'absolute', top: '30vh', right: '15vw' }}>
          <Tag idx={0} title={'VERIFY'} />
        </Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            position: 'absolute',
            bottom: '10px',
            right: '10px'
          }}
        >
          <TitleGreen>Join</TitleGreen>
          <UnderlineGreen />
          <TitleGreen>Us</TitleGreen>
          <UnderlineGreen style={{ width: '20vw' }} />
        </Box>
      </Box>
    </Box>
  )
}
