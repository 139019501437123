import { Box, Stack, Typography } from '@mui/material'
import logo from '../../assets/images/bottom_icon.png'
import Image from 'components/Image'
import { routes } from 'constants/routes'
import { Link } from 'react-router-dom'

const Tabs = [
  { title: 'HOME', route: routes.home },
  {
    title: 'E-SPORTS LOUNGE',
    route: routes.lounge
  },
  { title: 'WEB 3 ACADEMY', route: routes.home },
  { title: 'TOURNAMENT', route: routes.home }
]

export default function SimpleFooter() {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '200px 1fr 200px',
        alignItems: 'center',
        justifyItems: 'center',
        padding: '1rem 0.3rem'
      }}
    >
      <Image style={{ width: '2.5rem' }} src={logo} alt={'logo'} />
      <Box display={'grid'} justifyItems="center">
        <Stack direction="row" spacing="0.25rem">
          {Tabs.map(item => (
            <Link
              key={item.title}
              to={item.route}
              style={{
                textDecoration: 'none',
                fontSize: '0.2rem',
                fontFamily: 'SCDreamMedium',
                color: '#fff'
              }}
            >
              {item.title}
            </Link>
          ))}
        </Stack>
        <Typography mt="0.3rem" fontSize="0.2rem">
          Dplus Arena. All rights reserved
        </Typography>
      </Box>
    </Box>
  )
}
