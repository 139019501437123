import { Box, Grid, Stack } from '@mui/material'
import { DividerLine, WhiteText } from '../onboarding/Onboarding'
import BgTop from 'assets/images/academy-bg-top.jpg'
import BgBottom from 'assets/images/academy-bg-bottom.png'
import { MenuImageButton } from '../../../components/SecondaryMenu'
import { scrollToElement } from 'utils'
import Gamer1 from 'assets/images/lol/gamer_1.png'
import Gamer2 from 'assets/images/lol/gamer_2.png'
import General1 from 'assets/images/lol/general_1.png'
import General2 from 'assets/images/lol/general_2.png'
import OnboardingCard, { TitleCard } from '../onboarding/OnboardingCard'

export default function Career() {
  const CardList: TitleCard[] = [
    {
      tag: '#GAMER',
      isBeginner: true,
      title: 'LOL 기초 BASIC COURSE',
      pic: Gamer1
    },
    {
      tag: '#GENERAL',
      isBeginner: false,
      title: 'GENERAL SAMPLE TEXT',
      pic: General1
    },
    {
      tag: '#GAMER',
      isBeginner: true,
      title: 'LOL 기초 BASIC COURSE',
      pic: Gamer2
    },
    {
      tag: '#GENERAL',
      isBeginner: false,
      title: 'GENERAL SAMPLE TEXT',
      pic: General2
    }
  ]
  return (
    <Stack direction={'column'} width={'100%'}>
      <Box position={'relative'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <img src={BgTop} style={{ width: '100%' }} />
        <Box mt={254} position={'absolute'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <WhiteText fontSize={'1rem'} fontWeight={600} fontFamily={'SCDreamExtraBold'}>
            ROAD TO BE A PART OF WEB3
          </WhiteText>
          <DividerLine />
          <WhiteText fontSize={'0.3rem'} mt={58} mb={60}>
            You can start at Dplus Arena Academy
          </WhiteText>
          <Stack direction={'row'} spacing={30}>
            <MenuImageButton onClick={() => scrollToElement('next')}>GAMER COURSE</MenuImageButton>
            <MenuImageButton onClick={() => scrollToElement('next')}>GENERAL COURSE</MenuImageButton>
          </Stack>
        </Box>
      </Box>
      <Box position={'relative'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <img src={BgBottom} style={{ width: '100%' }} />
        <Grid container spacing={50} mt={'0.9rem'} id="next" width={'88%'} position={'absolute'}>
          {CardList.map((card, index) => {
            return (
              <Grid key={index} item xs={6}>
                <OnboardingCard title={card.title} tag={card.tag} isBeginner={card.isBeginner} pic={card.pic} />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Stack>
  )
}
