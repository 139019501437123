import { Box, Grid, Stack, Typography } from '@mui/material'
import TempPic from 'assets/images/temp-fan-galleries.png'
import { Tag } from '../web3/jobboard/JobBoard'
import Bg from 'assets/images/bg_6.jpg'

interface FanCardData {
  pic: string
  tag: string
  title: string
  tags: string[]
}

function FanCard({ data }: { data: FanCardData }) {
  return (
    <Box
      style={{
        border: '4px solid #000000',
        borderRadius: '20px',
        position: 'relative'
      }}
    >
      <img src={data.pic} width={'100%'} height={'auto'} style={{ borderRadius: '20px 20px 0 0' }} />
      <Box style={{ position: 'absolute', top: '20px', right: '20px' }}>
        <Tag title={data.tag} idx={0} />
      </Box>
      <Box style={{ background: '#626262', borderRadius: '0 0 20px 20px', padding: '25px' }}>
        <Typography style={{ fontSize: '30px', fontFamily: 'SCDreamMedium' }}>{data.title}</Typography>
        <Stack direction={'row'} mt={10} spacing={20}>
          {data.tags.map((tag, index) => {
            return (
              <Typography style={{ fontSize: '18px' }} key={index}>
                {tag}
              </Typography>
            )
          })}
        </Stack>
      </Box>
    </Box>
  )
}

export default function FanGalleries() {
  const fakeData: FanCardData[] = [
    {
      pic: TempPic,
      title: 'Title[45]',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    },
    {
      pic: TempPic,
      title: 'Title[45]',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    },
    {
      pic: TempPic,
      title: 'Title[45]',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    },
    {
      pic: TempPic,
      title: 'Title[45]',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    },
    {
      pic: TempPic,
      title: 'Title[45]',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    },
    {
      pic: TempPic,
      title: 'Title[45]',
      tag: '#DWG',
      tags: ['#DWG', '#DWG', '#DWG']
    }
  ]
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        backgroundImage: `url(${Bg})`,
        backgroundSize: 'cover'
      }}
    >
      <Typography
        style={{
          color: 'white',
          fontSize: '0.8rem',
          width: '54%',
          textAlign: 'center',
          marginTop: '30px',
          fontFamily: 'SCDreamExtraBold'
        }}
      >
        #DPLUS ARENA LOUNGE LOL TEAM FAN GALLERIES
      </Typography>
      <Stack direction={'row'}>
        <Tag title={'#DWG'} idx={0} />
        <Tag title={'#LOL'} idx={0} />
      </Stack>
      <Grid padding={130} spacing={40} container width={'100%'}>
        {fakeData.map((data, index) => {
          return (
            <Grid item key={index} md={4}>
              <FanCard data={data} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
