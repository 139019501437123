import { ImageButton } from 'pages/home'
import { Box, styled } from '@mui/material'

export const MenuImageButton = styled(ImageButton)(({ white }: { white?: boolean }) => ({
  color: white ? '#000' : '#fff',
  position: 'relative',
  cursor: 'pointer',
  backgroundImage: white ? `url('/images/button-bg2.svg') !important` : '',
  '&:hover': {
    color: '#000',
    backgroundImage: `url('/images/button-bg2.svg')`
  }
}))

interface SecondaryMenuProp {
  text: string
  event?: () => void
}

export default function SecondaryMenu({ list }: { list: SecondaryMenuProp[] }) {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ height: 0, border: '0.5px solid #ffffff' }} />
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: '-0.5rem',
          display: 'flex',
          '>*': {
            marginLeft: '0.2rem'
          }
        }}
      >
        {list.map(({ text, event }) => (
          <MenuImageButton
            key={text}
            sx={{
              cursor: event ? 'pointer' : 'auto'
            }}
            onClick={event}
          >
            {text}
          </MenuImageButton>
        ))}
      </Box>
    </Box>
  )
}

export function SecondaryMenuColumn({ list }: { list: SecondaryMenuProp[] }) {
  return (
    <Box
      sx={{
        right: 0,
        top: '-0.5rem',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {list.map(({ text, event }) => (
        <MenuImageButton
          key={text}
          sx={{
            cursor: event ? 'pointer' : 'auto',
            marginBottom: '0.3rem'
          }}
          onClick={event}
        >
          {text}
        </MenuImageButton>
      ))}
    </Box>
  )
}
