import { Suspense, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { styled } from '@mui/material'
import Header from '../components/Header'
import Polling from '../components/essential/Polling'
import Popups from '../components/essential/Popups'
import Web3ReactManager from '../components/essential/Web3ReactManager'
// import WarningModal from '../components/Modal/WarningModal'
import { ModalProvider } from 'context/ModalContext'
// import Footer from 'components/Footer'
import { routes } from 'constants/routes'
import Home from './home'
import Onboarding from './web3/onboarding/Onboarding'
import JobBoard from './web3/jobboard/JobBoard'
import Career from './web3/career'
import DWGLounge from './lounge/DWGLounge'
import LOLLounge from './lounge/LOLLounge'
import Lounge from './lounge'
import QuestLounge from './QuestLounge'
import QuestLoungeMyCommunity from './QuestLounge/MyCommunity'
import QuestLoungeMakeEQuest from './QuestLounge/MakeEQuest'
import Registration from './registration'
import Web3Academy from './web3'
import FanGalleries from './lounge/FanGalleries'
import TeamPic from './lounge/TeamPic'
import AllCompaigns from './QuestLounge/AllCompaigns'
import AllCommunities from './QuestLounge/AllCommunities'
import RegistrationByEmail from './registration/registration2'
import SeekingCreate from './web3/jobboard/SeekingCreate'
import JobBoardCreate from './web3/jobboard/JobBoardCreate'
import JobCardDetail from './web3/jobboard/JobCardDetail'
import Registration3 from './registration/registration3'
import Community from './community/Community'
import CommunitySetting from './QuestLounge/CommunitySetting'
import Campaign from './QuestLounge/Campaign'

const AppWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  minWidth: '1000px',
  // overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
    // height: '100vh'
  }
}))

const ContentWrapper = styled('div')({
  width: '100%',
  // maxHeight: '100vh',
  // overflow: 'auto',
  alignItems: 'center'
})

const BodyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: `calc(100vh - ${theme.height.header})`,
  // padding: '50px 0 80px',
  // justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  // overflowY: 'auto',
  // overflowX: 'hidden',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    minHeight: `calc(100vh - ${theme.height.header} - ${theme.height.mobileHeader})`
    // paddingTop: 20
  }
}))

export default function App() {
  useEffect(() => {
    function setRootFontSize() {
      const rootHtml = document.documentElement
      const rootWidth = rootHtml.clientWidth < 1000 ? 1000 : rootHtml.clientWidth
      const per = rootWidth / 1920
      rootHtml.style.fontSize = `${per * 100}px`
      window.addEventListener('resize', setRootFontSize, false)
    }

    setRootFontSize()
  }, [])
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Suspense fallback={null}>
      <ModalProvider>
        <AppWrapper id="app">
          <ContentWrapper>
            <Header />
            <BodyWrapper id="body">
              <Popups />
              <Polling />
              {/* <WarningModal /> */}
              <Web3ReactManager>
                <Switch>
                  <Route exact strict path={routes.home} component={Home} />
                  <Route exact strict path={routes.register} component={Registration} />
                  <Route exact strict path={routes.emailRegister} component={RegistrationByEmail} />
                  <Route exact strict path={routes.mypage} component={Registration3} />
                  <Route exact strict path={routes.DWGLounge} component={DWGLounge} />
                  <Route exact strict path={routes.LOLLounge} component={LOLLounge} />
                  <Route exact strict path={routes.lounge} component={Lounge} />
                  <Route exact strict path={routes.questLounge} component={QuestLounge} />
                  <Route exact strict path={routes.questLoungeMyCommunity} component={QuestLoungeMyCommunity} />
                  <Route exact strict path={routes.questLoungeCommunityInfo} component={Community} />
                  <Route exact strict path={routes.questLoungeCommunitySetting} component={CommunitySetting} />
                  <Route exact strict path={routes.questLoungeCampaign} component={Campaign} />
                  <Route exact strict path={routes.questLoungeMakeEQuest} component={QuestLoungeMakeEQuest} />
                  <Route exact strict path={routes.web3} component={Web3Academy} />
                  <Route exact strict path={routes.onboarding} component={Onboarding} />
                  <Route exact strict path={routes.job_board} component={JobBoard} />
                  <Route exact strict path={routes.seekingCreate} component={SeekingCreate} />
                  <Route exact strict path={routes.job_boardCreate} component={JobBoardCreate} />
                  <Route exact strict path={routes.job_boardDetail} component={JobCardDetail} />
                  <Route exact strict path={routes.career_academy} component={Career} />
                  <Route exact strict path={routes.DWGLoungeFan} component={FanGalleries} />
                  <Route exact strict path={routes.DWGTeamPic} component={TeamPic} />
                  <Route exact strict path={routes.questLoungeAllCampaigns} component={AllCompaigns} />
                  <Route exact strict path={routes.questLoungeAllCommunities} component={AllCommunities} />
                  <Route exact path="/" render={() => <Redirect to={routes.home} />} />
                  <Route exact path="*" render={() => <Redirect to={routes.home} />} />
                </Switch>
              </Web3ReactManager>
            </BodyWrapper>
            {/* <Footer /> */}
          </ContentWrapper>
        </AppWrapper>
      </ModalProvider>
    </Suspense>
  )
}
