import { Box, Input, Stack, styled } from '@mui/material'
import Bg from 'assets/images/registration-bg.png'
import { ReactComponent as Logo } from 'assets/images/registration-logo.svg'
import { useState } from 'react'
import { routes } from '../../constants/routes'
import { useHistory } from 'react-router-dom'
import { SecondaryMenuColumn } from '../../components/SecondaryMenu'
import { DividerLine, WhiteText } from '../web3/onboarding/Onboarding'

const BlackSolidInput = styled(Input)`
  border: 2px solid #000000;
  background-color: white;
  font-family: 'SCDreamMedium', serif;
  font-size: 0.2rem;
  color: black;
  padding: 0.2rem 0.3rem;
`
const Title = styled(WhiteText)`
  font-family: 'SCDreamExtraBold', serif;
  font-size: 1rem;
`
const Underline = styled(DividerLine)`
  margin-top: -20px;
`

export default function Registration() {
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const history = useHistory()
  return (
    <Box position={'relative'} width={'100%'}>
      <img src={Bg} style={{ width: '100%', position: 'absolute' }} />
      <Stack width={'100%'} sx={{ margin: '0.4rem 0.5rem' }} direction={'column'} style={{ position: 'absolute' }}>
        <Title>WELCOME</Title>
        <Underline />
        <Title>TO THE</Title>
        <Underline sx={{ width: '30%' }} />
        <Title>DWGG</Title>
        <Underline sx={{ width: '30%' }} />
        <Title>WORLD</Title>
        <Underline sx={{ width: '25%', marginLeft: '-50px' }} />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          marginTop: '127px',
          position: 'absolute'
        }}
      >
        <Logo />
        <Stack
          display={'flex'}
          alignItems={'center'}
          spacing={30}
          sx={{ mt: '57px' }}
          direction={'column'}
          width={'40%'}
        >
          <BlackSolidInput fullWidth placeholder={'Email'} value={email} onChange={e => setEmail(e.target.value)} />
          <BlackSolidInput fullWidth placeholder={'Password'} value={pwd} onChange={e => setPwd(e.target.value)} />
          <SecondaryMenuColumn
            list={[
              { text: 'LOG IN', event: () => history.push(routes.questLoungeMyCommunity) },
              { text: 'DISCORD LOG IN', event: () => history.push(routes.questLoungeMakeEQuest) },
              { text: 'TWITTER LOG IN', event: () => history.push(routes.questLoungeMakeEQuest) }
            ]}
          />
        </Stack>
      </Box>
    </Box>
  )
}
