import { Box, MenuItem, Stack, styled, Typography } from '@mui/material'
import Bg from 'assets/images/bg_seeking_create.jpg'
import Input from 'components/Input'
import Select from 'components/Select/Select'
import Divider from '../../../components/Divider'
import { MenuImageButton } from '../../../components/SecondaryMenu'

const MenuItemNarrow = styled(MenuItem)`
  color: #000;
  font-size: 0.2rem;
`

const SubTitle = styled(Typography)`
  margin-top: 0.8rem;
  font-size: 0.3rem;
`

const MarginDivider = styled(Divider)`
  margin-top: 0.4rem;
`

export default function SeekingCreate() {
  return (
    <Box
      style={{
        backgroundImage: `url(${Bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        width: '100%',
        padding: '100px',
        minHeight: '100vh'
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ width: '100%' }}>
          <Typography fontSize={'0.4rem'}>[TITLE] SEEKING A CM POSITION IN NFT PROJECT</Typography>
          <Divider />
        </Box>
        <Stack spacing="0.3rem" direction={'row'} mt="0.15rem">
          <Select width="3rem">
            <MenuItemNarrow>
              <Typography color="#000" fontWeight={500}>
                CATEGORY
              </Typography>
            </MenuItemNarrow>
          </Select>
          <Select width="3rem">
            <MenuItemNarrow>
              <Typography color="#000" fontWeight={500}>
                JOB
              </Typography>
            </MenuItemNarrow>
          </Select>
        </Stack>
      </Box>
      <SubTitle>WORK EXPERIENCE</SubTitle>
      <Input multiline rows={5} value="" style={{ background: 'white', borderRadius: 0 }} />
      <MarginDivider />
      <SubTitle>SKILLS</SubTitle>
      <Input multiline rows={5} value="" style={{ background: 'white', borderRadius: 0 }} />
      <MarginDivider />
      <Box style={{ display: 'flex', alignItems: 'baseline' }}>
        <SubTitle>DWG ACADEMY COURSES YOU ATTENDED</SubTitle>
        <Typography fontSize={20}> (OPTIONAL)</Typography>
      </Box>
      <Stack direction={'row'} spacing={'0.3rem'}>
        <Select width="2rem">
          <MenuItemNarrow>
            <Typography color={'black'}>CATEGORY</Typography>
          </MenuItemNarrow>
        </Select>
        <Select width="5rem">
          <MenuItemNarrow
            sx={{
              backgroundColor: '#fff'
            }}
          >
            <Typography color={'black'}>COURSE TITLE</Typography>
          </MenuItemNarrow>
        </Select>
      </Stack>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '40px', position: 'relative' }}>
        <Divider sx={{ width: '100%', marginTop: '40px' }} />
        <MenuImageButton sx={{ minWidth: '200px', position: 'absolute', right: '0px' }}>Create</MenuImageButton>
      </Box>
    </Box>
  )
}
