import { Box, styled } from '@mui/material'
import { routes } from 'constants/routes'
import { useHistory } from 'react-router-dom'
import homeBg from '../../assets/images/home-bg.png'
import Background from '../../components/Background'

const PageWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%'
})

export const ImageButton = styled(Box)(({ primary }: { primary?: boolean }) => ({
  height: '1rem',
  width: '3.5rem',
  fontSize: '0.25rem',
  color: 'rgba(255,255,255, 0.4)',
  zIndex: 9,
  position: 'absolute',
  fontWeight: 'bold',
  fontFamily: 'SCDreamHeavy',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundSize: '100% 100%',
  backgroundImage: primary ? `url('/images/button-bg1.png')` : `url('/images/button-bg2.png')`,
  '&:hover': {
    color: '#fff'
  }
}))

export default function Home() {
  const history = useHistory()

  return (
    <PageWrapper>
      <Background src={homeBg} />
      <ImageButton
        sx={{
          top: '3.6rem',
          right: '4rem'
        }}
      >
        COMING SOON
      </ImageButton>
      <ImageButton
        sx={{
          top: '3.6rem',
          right: '12rem'
        }}
      >
        COMING SOON
      </ImageButton>
      <ImageButton
        primary
        sx={{
          bottom: '4.3rem',
          cursor: 'pointer',
          left: '1.7rem'
        }}
        onClick={() => history.push(routes.web3)}
      >
        WEB 3 ACADEMY
      </ImageButton>
      <ImageButton
        primary
        onClick={() => history.push(routes.lounge)}
        sx={{
          cursor: 'pointer',
          bottom: '3rem',
          left: 'calc(50% - 1.75rem)'
        }}
      >
        E-SPORTS LOUNGE
      </ImageButton>
      <ImageButton
        // onClick={() => window.open('https://n.xilukeji.com/ddwg/public/html/dist/#/')}
        sx={{
          bottom: '4.3rem',
          cursor: 'pointer',
          right: '1.7rem'
        }}
      >
        TOURNAMENT
      </ImageButton>
    </PageWrapper>
  )
}
