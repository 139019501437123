export const routes = {
  home: '/home',
  register: '/register',
  emailRegister: '/register/email',
  mypage: '/mypage',
  web3: '/web3',
  onboarding: '/web3/onboarding',
  job_board: '/web3/job-board',
  seekingCreate: '/web3/job-board/seeking-create',
  job_boardCreate: '/web3/job-board/create',
  job_boardDetail: '/web3/job-board/detail',
  career_academy: '/web3/career-academy',

  lounge: '/lounge',
  questLounge: '/lounge/quest',
  questLoungeMyCommunity: '/lounge/quest/my_community',
  questLoungeCommunityInfo: '/lounge/quest/community_info',
  questLoungeCommunitySetting: '/lounge/quest/community_setting',
  questLoungeCampaign: '/lounge/quest/campaign',
  questLoungeMakeEQuest: '/lounge/quest/make_quest',
  questLoungeAllCampaigns: '/lounge/quest/all_compaigns',
  questLoungeAllCommunities: '/lounge/quest/all_communities',

  DWGLounge: '/lounge/quest/dwg',
  DWGLoungeFan: '/lounge/fan-galleries',
  DWGTeamPic: '/lounge/team-pic',
  LOLLounge: '/lounge/quest/dwg/lol'
}
