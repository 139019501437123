import { useCallback, useState } from 'react'
import Background from '../../components/Background'
import dwgBG from '../../assets/images/dwg-bg.png'
import dwgTeam from '../../assets/images/dwg-team.png'
import newsCover from '../../assets/images/news-cover.png'
import liveBG from '../../assets/images/live-bg.png'
import gameBG from '../../assets/images/game_bg1.png'
import gameBG2 from '../../assets/images/game-bg.png'
import youtube from '../../assets/images/youtube.png'
import pubg from '../../assets/images/pubg-cover.png'
import lol from '../../assets/images/lol-cover.png'
import { ReactComponent as Right } from '../../assets/svg/arrow-right.svg'
import { ReactComponent as Left } from '../../assets/svg/arrow-left.svg'
import { Box, styled, Typography } from '@mui/material'
import SecondaryMenu from 'components/SecondaryMenu'
import { GalleryImage } from 'components/Image'
import { routes } from 'constants/routes'
import { useHistory } from 'react-router-dom'
import SimpleFooter from 'components/Footer/SimpleFooter'
import Team1 from 'assets/images/lounge/team1.png'
import Team2 from 'assets/images/lounge/team2.png'
import Team3 from 'assets/images/lounge/team3.png'
import Team4 from 'assets/images/lounge/team4.png'
import Team5 from 'assets/images/lounge/team5.png'
import Team6 from 'assets/images/lounge/team6.png'
import Team7 from 'assets/images/lounge/team7.png'
import Team8 from 'assets/images/lounge/team8.png'

const Wrapper = styled(Box)({
  height: '100%',
  width: '100%'
})

export const Context = styled(Box)({
  position: 'relative',
  width: '100%'
})

export const Title = styled(Typography)({
  fontSize: '0.6rem',
  color: '#fff',
  fontFamily: 'SCDreamExtraBold',
  fontWeight: 'bold'
})

const newsList = [
  { title: 'NEWS TITLE', desc: 'News description sample text...', cover: newsCover, time: '2023.01.10' },
  { title: 'NEWS TITLE', desc: 'News description sample text...', cover: newsCover, time: '2023.01.10' },
  { title: 'NEWS TITLE', desc: 'News description sample text...', cover: newsCover, time: '2023.01.10' },
  { title: 'NEWS TITLE', desc: 'News description sample text...', cover: newsCover, time: '2023.01.10' },
  { title: 'NEWS TITLE', desc: 'News description sample text...', cover: newsCover, time: '2023.01.10' }
]

const games = [
  { name: '#PUBG MOBILE', cover: pubg },
  { name: '#LOL', cover: lol, route: routes.LOLLounge },
  { name: '#PUBG MOBILE', cover: pubg },
  { name: '#LOL', cover: lol, route: routes.LOLLounge },
  { name: '#PUBG MOBILE', cover: pubg }
]

export default function DWGLounge() {
  const [gamePosition, setGamePosition] = useState(0)

  const gamePositionCallback = useCallback((pos: number) => {
    const page = Math.ceil(games.length / 3) - 1
    if (pos < 0) {
      setGamePosition(page)
    } else if (pos > page) {
      setGamePosition(0)
    } else {
      setGamePosition(pos)
    }
  }, [])

  const history = useHistory()
  return (
    <Wrapper>
      <Context sx={{ mt: '-1rem' }}>
        <Background style={{ opacity: 0.5 }} src={dwgBG} />
        <Box
          sx={{
            width: '100%',
            top: '1.5rem',
            padding: '0 1.26rem',
            position: 'absolute'
          }}
        >
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Title>#Dplus LOUNGE</Title>
            <Typography style={{ fontSize: '0.3rem' }}>#Dplus News</Typography>
          </Box>
          <Box sx={{ borderTop: '1px solid #ffffff', margin: '0.1rem 0 0.4rem 0' }} />
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1.2fr', gap: '0.8rem' }}>
            <Box>
              <img style={{ width: '100%', marginBottom: '0.2rem' }} src={dwgTeam} alt="" />
              <RowNewsItem title="NEWS TITLE" desc="News description sample text..." time="2023.01.10" />
            </Box>
            <Box sx={{ paddingRight: '0.2rem', height: '6.6rem', overflowY: 'auto' }}>
              {newsList.map(({ title, desc, cover, time }, index) => {
                return (
                  <Box key={index} style={{ display: 'flex', marginBottom: '0.3rem' }}>
                    <img
                      style={{ width: '3.2rem', height: '2rem', marginRight: '0.2rem', cursor: 'pointer' }}
                      src={cover}
                      alt=""
                    />
                    <RowNewsItem title={title} desc={desc} time={time} />
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Context>
      <Context>
        <Background src={liveBG} />
        <Box
          sx={{
            width: '100%',
            top: '1rem',
            padding: '0 1.26rem',
            position: 'absolute'
          }}
        >
          <Title>Dplus Station</Title>
          <SecondaryMenu list={[{ text: 'See All' }]} />

          <Box mt="1rem">
            <img style={{ width: '13rem', display: 'block', margin: 'auto' }} src={youtube} alt={''} />
          </Box>
        </Box>
      </Context>
      <Context>
        <Background src={gameBG} />
        <Box
          sx={{
            width: '100%',
            top: '1rem',
            padding: '0 1.26rem',
            position: 'absolute'
          }}
        >
          <Title>TEAMS</Title>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop: '1rem',
              padding: '0 1.5rem'
            }}
          >
            <Left style={{ cursor: 'pointer' }} onClick={() => gamePositionCallback(gamePosition - 1)} />
            {games.slice(gamePosition * 3, (gamePosition + 1) * 3).map(({ name, cover, route }, index) => {
              return (
                <Box key={index} display="grid" justifyItems={'center'}>
                  <img style={{ width: '3.75rem', border: '1px solid #5E7B9A', borderRadius: 20 }} src={cover} alt="" />
                  <Typography
                    sx={{
                      mt: '0.4rem',
                      height: '0.88rem',
                      width: '2.43rem',
                      cursor: 'pointer',
                      backgroundColor: '#9CF8EE',
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: '0.25rem',
                      fontFamily: 'SCDreamMedium',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 99
                    }}
                    onClick={() => route && history.push(route)}
                  >
                    {name}
                  </Typography>
                </Box>
              )
            })}
            <Right style={{ cursor: 'pointer' }} onClick={() => gamePositionCallback(gamePosition + 1)} />
          </Box>
        </Box>
      </Context>

      <Context>
        <Background src={gameBG2} />
        <Box
          sx={{
            width: '100%',
            top: '1rem',
            padding: '0 1.26rem',
            position: 'absolute'
          }}
        >
          <Title>GALLERIES</Title>
          <SecondaryMenu list={[{ text: 'SEE ALL' }]} />

          <Box
            sx={{
              padding: '1rem 0.8rem 0',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gap: '0.35rem'
            }}
          >
            {[Team1, Team2, Team3, Team4, Team5, Team6, Team7, Team8].map((pic, index) => (
              <GalleryImage src={pic} key={index} />
            ))}
          </Box>
        </Box>
      </Context>

      <SimpleFooter />
    </Wrapper>
  )
}

function RowNewsItem({ time, title, desc }: { desc: string; title: string; time: string }) {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'end', mb: 10 }}>
        <Typography
          sx={{
            color: '#9CF8EE',
            lineHeight: 1,
            fontWeight: 500,
            fontSize: '0.4rem',
            fontFamily: 'SCDreamExtraBold'
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ marginLeft: '0.3rem', fontSize: '0.15rem', fontFamily: 'SCDreamLight' }}>{time}</Typography>
      </Box>
      <Typography sx={{ fontSize: '0.30rem' }} width="100%" noWrap>
        {desc}
      </Typography>
    </Box>
  )
}
