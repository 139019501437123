import { Box, Stack, Typography } from '@mui/material'
import Input from 'components/Input'
import SecondaryMenu, { MenuImageButton } from 'components/SecondaryMenu'
import { routes } from 'constants/routes'
import { Context, Title } from 'pages/lounge/DWGLounge'
import { useHistory } from 'react-router-dom'
import bg_11 from '../../assets/images/lounge/bg_11.png'

export const communityBtnStyle = {
  backgroundColor: '#30F9C0',
  fontSize: '0.2rem',
  fontWeight: 500,
  color: '#000',
  width: '1.8rem',
  height: '0.47rem',
  borderRadius: '0.3rem',
  fontFamily: 'SCDreamLight',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export default function MyCommunity() {
  const history = useHistory()
  return (
    <Context
      sx={{
        minHeight: '100vh',
        backgroundImage: `url(${bg_11})`,
        backgroundSize: '100% auto'
      }}
    >
      <Box
        sx={{
          width: '100%',
          top: '1rem',
          padding: '0 1rem',
          position: 'absolute'
        }}
      >
        <Title>MY COMMUNITY</Title>
        <SecondaryMenu list={[]} />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1.25rem',
            mt: '0.6rem'
          }}
        >
          <Stack spacing="0.3rem">
            <Input value={''} label="COMMUNITY NAME" />
            <Input value={''} label="INTRODUCTION" />
            <Box display={'flex'} alignItems="center">
              <Typography fontSize={'0.25rem'} mr="0.2rem" fontWeight="bold">
                Logo Image
              </Typography>
              <label style={communityBtnStyle}>
                <input type="file" style={{ width: 0, height: 0 }} />
                Upload
              </label>
            </Box>
            <Input value={''} label="COMMUNITY URL (IF CANNOT BE CHANGED AFTER CREATION)" />
          </Stack>
          <Stack spacing="0.3rem">
            <Input value={''} label="WEBSITE URL" />
            <Input value={''} label="TWITTER" />
            <Input value={''} label="DISCORD" />
            <Input value={''} label="TELEGRAM" />
          </Stack>
        </Box>
        <Box display={'flex'} justifyContent="center" mt="0.68rem">
          <MenuImageButton white onClick={() => history.push(routes.questLoungeCommunityInfo)}>
            SAVE
          </MenuImageButton>
        </Box>
      </Box>
    </Context>
  )
}
