import { Box, Grid, Stack, Typography } from '@mui/material'
import Bg from 'assets/images/bg-all-campaigns.jpg'
import { Tag } from '../web3/jobboard/JobBoard'

export function CompaignsCard() {
  return (
    <Box
      style={{
        background: 'white',
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        aspectRatio: '1 / 1'
      }}
    >
      <Typography style={{ margin: 'auto', color: 'black' }}>Image Preview</Typography>
      <Typography
        style={{
          textAlign: 'center',
          position: 'absolute',
          fontSize: '0.25rem',
          width: '100%',
          margin: '0 auto',
          fontFamily: 'SCDreamMedium',
          bottom: '30px',
          color: 'black'
        }}
      >
        #NAME
      </Typography>
    </Box>
  )
}

export default function AllCompaigns() {
  const fakeTag = ['#DWG POINT', '#TOKEN', '#NFT']
  const fakeData = ['', '', '', '', '', '', '', '']
  return (
    <Box
      style={{
        background: `url(${Bg})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography
        style={{
          color: 'white',
          fontSize: '80px',
          fontFamily: 'SCDreamExtraBold',
          width: '54%',
          textAlign: 'center',
          marginTop: '30px'
        }}
      >
        ALL Campaigns
      </Typography>
      <Stack direction={'row'}>
        {fakeTag.map((tag, index) => {
          return <Tag idx={0} title={tag} key={index} />
        })}
      </Stack>
      <Grid padding={130} spacing={40} container width={'100%'}>
        {fakeData.map((data, index) => {
          return (
            <Grid item key={index} md={4}>
              <CompaignsCard />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
