import { useState, useCallback } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { AppBar, Box, IconButton, MenuItem, Stack, styled as muiStyled, styled, Typography } from '@mui/material'
import { ExternalLink } from 'theme/components'
import Web3Status from './Web3Status'
import { ShowOnMobile } from 'theme/index'
import PlainSelect from 'components/Select/PlainSelect'
import Image from 'components/Image'
import logo from '../../assets/images/bottom_icon.png'
// import { routes } from 'constants/routes'
import MobileMenu from './MobileMenu'
import NetworkSelect from './NetworkSelect'
import { routes } from 'constants/routes'
import DropDown from 'assets/images/dropdown.png'

interface TabContent {
  title: string
  route?: string
  link?: string
  titleContent?: JSX.Element
}

interface Tab extends TabContent {
  subTab?: TabContent[]
}

export const Tabs: Tab[] = [
  { title: 'HOME', route: routes.home },
  {
    title: 'E-SPORTS LOUNGE',
    route: routes.lounge,
    subTab: [
      {
        title: 'FAN LOUNGE',
        route: routes.lounge
        // route: routes.DWGLounge
      },
      {
        title: 'GAMER LOUNGE',
        // route: routes.questLounge
        route: routes.lounge
      }
    ]
  },
  { title: 'WEB 3 ACADEMY', route: routes.web3 },
  // { title: 'TOURNAMENT', link: 'https://n.xilukeji.com/ddwg/public/html/dist/#/' }
  { title: 'TOURNAMENT', route: 'coming' }
]

const navLinkSX = ({}: any) => ({
  textDecoration: 'none',
  fontSize: 14,
  color: '#fff',
  '&:hover': {
    color: '#9af8ed'
  }
})

const StyledNavLink = styled(NavLink)(navLinkSX)

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  height: theme.height.header,
  backgroundColor: theme.palette.background.paper,
  display: 'grid',
  gridTemplateColumns: '3rem 1fr 3rem',
  alignItems: 'center',
  boxShadow: 'none',
  // padding: '0 40px 0 25px!important',
  zIndex: theme.zIndex.drawer,
  // borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  // [theme.breakpoints.down('md')]: {
  //   position: 'fixed',
  //   bottom: 0,
  //   left: 0,
  //   top: 'unset',
  //   borderTop: '1px solid ' + theme.bgColor.bg4,
  //   justifyContent: 'center'
  // },
  '& .link': {
    textDecoration: 'none',
    fontSize: '0.2rem',
    color: '#fff',
    fontWeight: 'bold',
    opacity: 1,
    marginRight: 30,
    // paddingBottom: '30px',
    // borderBottom: '2px solid transparent',
    '&.active': {
      opacity: 1,
      color: '#9af8ed',
      borderColor: theme.palette.text.primary
    },
    '&:hover': {
      color: '#9af8ed',
      opacity: 1
    }
  },
  [theme.breakpoints.down('lg')]: {
    '& .link': { marginRight: 20 }
    // padding: '0 24px 0 0!important'
  },
  [theme.breakpoints.down('md')]: {
    // position: 'fixed'
  },
  [theme.breakpoints.down('sm')]: {
    // height: theme.height.mobileHeader
    // padding: '0 20px!important'
  }
}))

const Filler = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    height: theme.height.header
    // display: 'block'
  },
  [theme.breakpoints.down('sm')]: {
    height: theme.height.mobileHeader,
    padding: '0 20px'
  }
}))

const MainLogo = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  marginLeft: '40px',
  justifyContent: 'center',
  '& img': {
    height: '0.55rem'
  },
  '&:hover': {
    cursor: 'pointer'
  },
  [theme.breakpoints.down('sm')]: {
    '& img': { height: 40 },
    marginBottom: -10
  }
}))

const LinksWrapper = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    marginLeft: 0
  }
}))

const StyledRightText = styled(Typography)({
  fontWeight: 'bold',
  fontFamily: 'SCDreamMedium',
  cursor: 'pointer',
  fontSize: '0.2rem'
})

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { pathname } = useLocation()
  const history = useHistory()

  const handleMobileMenueDismiss = useCallback(() => {
    setMobileMenuOpen(false)
  }, [])

  return (
    <>
      <MobileMenu isOpen={mobileMenuOpen} onDismiss={handleMobileMenueDismiss} />
      <Filler />
      <StyledAppBar>
        <Box>
          <MainLogo id={'logo'} to={'/'}>
            <Image src={logo} alt={'logo'} />
          </MainLogo>
        </Box>
        <LinksWrapper>
          {Tabs.map(({ title, route, subTab, link, titleContent }, idx) =>
            subTab ? (
              <Box
                sx={{
                  marginRight: {
                    xs: 20,
                    lg: 30
                  },
                  display: 'flex',
                  paddingBottom: 2
                }}
                key={title + idx}
              >
                <PlainSelect
                  key={title + idx}
                  placeholder={title}
                  onClick={() => history.push(routes.lounge)}
                  autoFocus={false}
                  active={pathname.includes(routes.lounge)}
                  width={title === 'Test' ? '70px' : undefined}
                  style={{
                    height: '16px',
                    fontSize: '0.2rem'
                  }}
                >
                  {subTab.map((sub, idx) =>
                    sub.link ? (
                      <MenuItem
                        key={sub.link + idx}
                        sx={{ backgroundColor: 'transparent!important', background: 'transparent!important' }}
                        selected={false}
                      >
                        <ExternalLink
                          href={sub.link}
                          className={'link'}
                          color="#00000050"
                          sx={{
                            fontSize: '0.15rem',
                            fontFamily: 'SCDreamMedium',
                            '&:hover': {
                              color: '#232323!important'
                            }
                          }}
                        >
                          {sub.titleContent ?? sub.title}
                        </ExternalLink>
                      </MenuItem>
                    ) : (
                      <MenuItem key={sub.title + idx}>
                        <StyledNavLink to={sub.route ?? ''}>{sub.titleContent ?? sub.title}</StyledNavLink>
                      </MenuItem>
                    )
                  )}
                </PlainSelect>
              </Box>
            ) : link ? (
              <ExternalLink href={link} className={'link'} key={link + idx} style={{ fontSize: '0.2rem' }}>
                {titleContent ?? title}
              </ExternalLink>
            ) : (
              <NavLink
                key={title + idx}
                id={`${route}-nav-link`}
                to={route ?? ''}
                className={
                  (route
                    ? pathname.includes(route)
                      ? 'active'
                      : pathname.includes('account')
                      ? route.includes('account')
                        ? 'active'
                        : ''
                      : ''
                    : '') + ' link'
                }
              >
                {titleContent ?? title}
              </NavLink>
            )
          )}
        </LinksWrapper>

        <Stack alignItems="center" spacing={{ xs: '6px', sm: '20px' }} direction="row">
          <StyledRightText onClick={() => history.push(routes.mypage)}>MY PAGE</StyledRightText>
          <Box display={'flex'} alignItems="center">
            <StyledRightText mr={5}>EN</StyledRightText>
            <Image src={DropDown} />
          </Box>
          <StyledRightText>HELP</StyledRightText>
          {false && (
            <>
              <NetworkSelect />
              <Web3Status />
              <ShowOnMobile breakpoint="md">
                <IconButton
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    height: { xs: 24, sm: 32 },
                    width: { xs: 24, sm: 32 },
                    mb: { xs: 0, sm: 15 },
                    mt: { xs: 0, sm: 8 },
                    padding: '4px',
                    borderRadius: '8px'
                  }}
                  onClick={() => {
                    setMobileMenuOpen(open => !open)
                  }}
                >
                  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" stroke="#252525">
                    <path d="M1 1H13" strokeWidth="1.4" strokeLinecap="round" />
                    <path d="M1 7H13" strokeWidth="1.4" strokeLinecap="round" />
                  </svg>
                </IconButton>
              </ShowOnMobile>
            </>
          )}
        </Stack>
      </StyledAppBar>
    </>
  )
}
