import { Box, Stack, styled, Typography } from '@mui/material'
import Bg from 'assets/images/bg-community.jpg'
import { MenuImageButton } from '../../components/SecondaryMenu'
import Divider from '../../components/Divider'
import { ImagePreDiv } from '../registration/registration3'
import { Tag } from '../web3/jobboard/JobBoard'
import { ReactComponent as SocialIcon } from 'assets/svg/social-media-icons.svg'
import { CompaignsCard } from '../QuestLounge/AllCompaigns'
import { routes } from 'constants/routes'
import { useHistory } from 'react-router-dom'

const TextUnderLine = styled(Typography)`
  text-decoration: underline;
  font-size: 40px;
`
export default function Community() {
  const history = useHistory()
  const fakeCard = ['', '', '', '', '']
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100vh'
      }}
    >
      <Box
        style={{
          background: `url(${Bg})`,
          display: 'flex',
          width: '100%',
          height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '120px'
        }}
      >
        <Box style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Box style={{ width: '100%' }}>
            <Typography fontSize={'60px'}>#COMMUNITY NAME</Typography>
            <Divider />
          </Box>
          <Box mt={30}>
            <MenuImageButton onClick={() => history.push(routes.questLoungeCommunitySetting)}>CHANGE</MenuImageButton>
          </Box>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '60px' }}>
          <ImagePreDiv />
          <Stack
            spacing={32}
            marginLeft={30}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <Typography fontSize={30}>
              Description Sample Text…Description Sample Text…
              <br />
              Description Sample Text…Description Sample Text…
              <br />
              Description Sample Text…Description Sample Text…
            </Typography>
            <Tag idx={0} title={'+ FOLLOW'} />
            <SocialIcon />
          </Stack>
        </Box>
      </Box>
      <Box width={'100%'} padding={'120px'}>
        <Stack spacing={50} direction={'row'}>
          <TextUnderLine color={'#30F9C0'} fontFamily={'SCDreamMedium'}>
            ONGOING QUEST
          </TextUnderLine>
          <TextUnderLine>COMPLETE</TextUnderLine>
          <TextUnderLine>DRAFT</TextUnderLine>
        </Stack>
        <Stack spacing={10} direction={'row'} mt={100}>
          {fakeCard.map(index => {
            return <CompaignsCard key={index} />
          })}
        </Stack>
      </Box>
    </Box>
  )
}
