import { Box, styled, Typography } from '@mui/material'
import Bg from 'assets/images/bg-registration3.jpg'
import { Tag } from '../web3/jobboard/JobBoard'
import { ReactComponent as Profile } from 'assets/svg/profile.svg'

const BlackTitle = styled(Typography)`
  color: black;
  font-size: 40px;
  font-family: 'SCDreamMedium', serif;
  min-width: 300px;
`
const BlackContent = styled(Typography)`
  color: black;
  font-size: 20px;
`

const ContentRow = styled(Box)`
  padding: 25px 0;
  display: flex;
  align-items: center;
  width: 100%;
`

const BlackDivider = styled(Box)`
  border: 0.5px solid black;
  width: 100%;
`
const ImageDiv = styled(Box)`
  width: 350px;
  aspect-ratio: 1/1;
  background: white;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
`

export function ImagePreDiv() {
  return (
    <ImageDiv>
      <Typography color={'black'}>Image Preview</Typography>
    </ImageDiv>
  )
}

export default function Registration3() {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: '50px',
        justifyContent: 'space-between',
        height: '100vh',
        alignItems: 'center',
        backgroundImage: `url(${Bg})`,
        backgroundSize: 'cover'
      }}
    >
      <Box
        sx={{
          width: '45%'
        }}
      >
        <BlackTitle sx={{ fontFamily: 'SCDreamExtraBold', fontSize: '60px' }}>MY PAGE</BlackTitle>
        <BlackDivider />
        <ContentRow>
          <BlackTitle>Email address</BlackTitle>
          <BlackContent></BlackContent>
        </ContentRow>
        <BlackDivider />
        <ContentRow>
          <BlackTitle>Password</BlackTitle>
          <Tag idx={1} title={'CHANGE'} />
        </ContentRow>
        <BlackDivider />
        <ContentRow>
          <BlackTitle>My wallet</BlackTitle>
          <BlackContent></BlackContent>
        </ContentRow>
        <BlackDivider />
        <ContentRow>
          <BlackTitle>Nickname</BlackTitle>
          <BlackContent></BlackContent>
          <Tag idx={1} title={'CHANGE'} />
        </ContentRow>
        <BlackDivider />
      </Box>
      <Box
        sx={{
          width: '45%'
        }}
      >
        <Box display={'flex'}>
          <ImagePreDiv />
          <Box style={{ marginLeft: '-15px' }}>
            <Profile />
          </Box>
        </Box>
        <BlackDivider marginTop={10} />
        <ContentRow>
          <BlackTitle>My Code</BlackTitle>
          <BlackContent fontFamily={'SCDreamMedium'}>- Point</BlackContent>
        </ContentRow>
        <BlackDivider />
        <ContentRow>
          <BlackTitle>My Point</BlackTitle>
          <BlackContent fontFamily={'SCDreamMedium'}>- Point</BlackContent>
        </ContentRow>
        <BlackDivider />
      </Box>
    </Box>
  )
}
