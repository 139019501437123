import { Box, Grid, Typography } from '@mui/material'
import Bg from 'assets/images/bg-all-communities.jpg'
import { CompaignsCard } from './AllCompaigns'

export default function AllCommunities() {
  const fakeData = ['', '', '', '', '', '', '', '']
  return (
    <Box
      style={{
        background: `url(${Bg})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography
        style={{
          color: '#9CF8EE',
          fontSize: '80px',
          fontFamily: 'SCDreamExtraBold',
          width: '54%',
          textAlign: 'center',
          marginTop: '30px'
        }}
      >
        ALL Campaigns
      </Typography>
      <Grid padding={130} spacing={40} container width={'100%'}>
        {fakeData.map((data, index) => {
          return (
            <Grid item key={index} md={4}>
              <CompaignsCard />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
