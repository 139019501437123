import { Box, Stack, styled, Typography } from '@mui/material'

export interface TitleCard {
  isBeginner: boolean
  title: string
  pic?: string
  tag?: string | string[]
  click?: () => void
}

const Rounded = styled(Typography)`
  font-size: 0.3rem;
  padding: 0.2rem 0.3rem;
  width: fit-content;
  font-family: 'SCDreamMedium', serif;
  border-radius: 0.44rem;
  margin: 0.4rem;
  position: absolute;
  color: black;
`

const RoundedList = styled(Typography)`
  font-size: 0.3rem;
  padding: 0.2rem 0.3rem;
  width: fit-content;
  font-family: 'SCDreamMedium', serif;
  border-radius: 0.44rem;
  color: black;
`

const TagList = styled(Stack)`
  position: absolute;
  margin: 0.4rem;
`

const Title = styled(Typography)`
  padding: 0.3rem 0.5rem;
  color: black;
  border-top: 4px solid #000000;
  font-family: 'SCDreamExtraBold', serif;
  font-size: 0.3rem;
`

export function Beginner({ tag }: { tag?: string | string[] }) {
  if (Array.isArray(tag)) {
    return (
      <TagList direction={'row'} spacing={20}>
        {tag.map((t, index) => {
          return (
            <RoundedList key={index} sx={{ backgroundColor: '#30F9C0' }}>
              {t ? t : '#BEGINNER'}
            </RoundedList>
          )
        })}
      </TagList>
    )
  }
  return <Rounded sx={{ backgroundColor: '#30F9C0' }}>{tag ? tag : '#BEGINNER'}</Rounded>
}

export function Advanced({ tag }: { tag?: string | string[] }) {
  if (Array.isArray(tag)) {
    return (
      <TagList direction={'row'} spacing={20}>
        {tag.map((t, index) => {
          return (
            <RoundedList key={index} sx={{ backgroundColor: '#9CF8EE' }}>
              {t ? t : '#ADVANCED'}
            </RoundedList>
          )
        })}
      </TagList>
    )
  }
  return <Rounded sx={{ backgroundColor: '#9CF8EE' }}>{tag ? tag : '#ADVANCED'}</Rounded>
}

export function BeginnerTitle({ title }: { title: string }) {
  return <Title sx={{ backgroundColor: '#30F9C0' }}>{title}</Title>
}

export function AdvancedTitle({ title }: { title: string }) {
  return <Title sx={{ backgroundColor: '#9CF8EE' }}>{title}</Title>
}

export default function OnboardingCard(data: TitleCard) {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', border: '4px solid #000000', backgroundColor: 'white' }}
      onClick={data.click}
    >
      <Box display={'flex'} flexDirection={'column'} position={'relative'}>
        {data.isBeginner ? <Beginner tag={data.tag} /> : <Advanced tag={data.tag} />}
        {data.pic ? (
          <img width={'100%'} alt={'Image Preview'} style={{ aspectRatio: '8/5' }} src={data.pic} />
        ) : (
          <Typography
            color={'black'}
            fontWeight={600}
            sx={{ width: 'fit-content', alignSelf: 'center', margin: '0.9rem auto 1.3rem auto' }}
          >
            Image Preview
          </Typography>
        )}
      </Box>
      {data.isBeginner ? <BeginnerTitle title={data.title} /> : <AdvancedTitle title={data.title} />}
    </Box>
  )
}
