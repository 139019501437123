import { Box, MenuItem, Stack, styled, Typography } from '@mui/material'
import Bg from 'assets/images/bg_seeking_create.jpg'
import Button from 'components/Button/Button'
import Modal from 'components/Modal'
import Select from 'components/Select/Select'
import { routes } from 'constants/routes'
import useModal from 'hooks/useModal'
import { useHistory } from 'react-router-dom'
import Divider from '../../../components/Divider'
import { MenuImageButton } from '../../../components/SecondaryMenu'

const MenuItemNarrow = styled(MenuItem)`
  color: #000;
  font-size: 0.2rem;
`

const SubTitle = styled(Typography)`
  margin-top: 1rem;
  font-size: 0.3rem;
`

const SubText = styled(Typography)`
  margin-top: 0.1rem;
  font-size: 0.25rem;
`

const MarginDivider = styled(Divider)`
  margin-top: 1rem;
`

export default function SeekingCreate() {
  const { showModal } = useModal()
  return (
    <Box
      style={{
        backgroundImage: `url(${Bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        width: '100%',
        padding: '100px',
        minHeight: '100vh'
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ width: '100%' }}>
          <Typography fontSize={'0.4rem'}>[TITLE] SEEKING A CM POSITION IN NFT PROJECT</Typography>
          <Divider />
        </Box>
        <Stack spacing="0.3rem" direction={'row'} mt="0.15rem">
          <Select width="3rem">
            <MenuItemNarrow>
              <Typography color="#000" fontWeight={500}>
                CATEGORY
              </Typography>
            </MenuItemNarrow>
          </Select>
          <Select width="3rem">
            <MenuItemNarrow>
              <Typography color="#000" fontWeight={500}>
                JOB
              </Typography>
            </MenuItemNarrow>
          </Select>
        </Stack>
      </Box>

      <SubTitle>ESSENTIAL FUNCTIONS</SubTitle>
      <SubText>
        Description Sample Text…Description Sample Text…Description Sample Text…Description Sample Text…
      </SubText>
      <SubText>
        Description Sample Text…Description Sample Text…Description Sample Text…Description Sample Text…
      </SubText>

      <MarginDivider />
      <SubTitle>QUALIFICATIONS</SubTitle>
      <SubText>
        Description Sample Text…Description Sample Text…Description Sample Text…Description Sample Text…
      </SubText>
      <SubText>
        Description Sample Text…Description Sample Text…Description Sample Text…Description Sample Text…
      </SubText>

      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '1rem', position: 'relative' }}>
        <Divider sx={{ width: '100%' }} />
        <MenuImageButton
          sx={{ minWidth: '200px', position: 'absolute', right: '0px' }}
          onClick={() => showModal(<MsgModal />)}
        >
          Create
        </MenuImageButton>
      </Box>
    </Box>
  )
}

function MsgModal() {
  const history = useHistory()
  const { hideModal } = useModal()
  return (
    <Modal width="10rem" maxWidth="10rem">
      <Box
        sx={{
          height: '5rem',
          backgroundColor: '#000',
          border: '0.1rem solid #30F9C0',
          padding: '0.7rem'
        }}
        height={'4rem'}
        display="grid"
        alignItems={'center'}
        justifyItems="center"
      >
        <Box>
          <Typography mb="0.2rem" fontSize="0.4rem">
            To create a Hiring page, you need to pay $$$
          </Typography>
          <Box display={'flex'} justifyContent="center" mt="0.2rem">
            <Button
              width="2.4rem"
              height="0.88rem"
              style={{
                borderRadius: '0.44rem',
                fontFamily: 'SCDreamMedium',
                fontSize: '0.4rem',
                background: '#30F9C0',
                color: '#000'
              }}
              onClick={() => {
                hideModal()
                history.push(routes.seekingCreate)
              }}
            >
              PAY
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
