import BackgroundImg from 'assets/images/web3-bg.jpg'
import { Box, Grid, Stack, styled, Typography } from '@mui/material'
import Background from '../../../components/Background'
import OnboardingCard, { TitleCard } from './OnboardingCard'
import { MenuImageButton } from '../../../components/SecondaryMenu'
import { scrollToElement } from 'utils'
import Adv1 from 'assets/images/advanced1.png'
import Adv2 from 'assets/images/advanced2.png'
import Begin1 from 'assets/images/beginners1.png'
import Begin2 from 'assets/images/beginners2.png'

const Wrapper = styled(Box)({
  width: '100%',
  position: 'relative',
  height: 'fit-content'
})

export const DividerLine = styled(Box)`
  border: 1px solid #ffffff;
  height: 1px;
  width: 88%;
`

export const WhiteText = styled(Typography)`
  color: white;
`

export default function Onboarding() {
  const CardList: TitleCard[] = [
    {
      isBeginner: true,
      title: 'WEB3 101 FOR BEGINNERS',
      pic: Begin1
    },
    {
      isBeginner: false,
      title: 'QUEST FOR ADVANCED USERS',
      pic: Adv1
    },
    {
      isBeginner: true,
      title: 'WEB3 101 FOR BEGINNERS',
      pic: Begin2
    },
    {
      isBeginner: false,
      title: 'QUEST FOR ADVANCED USERS',
      pic: Adv2
    }
  ]
  return (
    <Wrapper>
      <Background src={BackgroundImg} sx={{ position: 'absolute' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'absolute',
          width: '100%'
        }}
      >
        <WhiteText sx={{ fontSize: '1rem', fontFamily: 'SCDreamExtraBold', fontWeight: '600' }} mt={'2.5rem'}>
          LEARN ALL ABOUT WEB3
        </WhiteText>
        <DividerLine />
        <WhiteText sx={{ mt: '6vh', textAlign: 'center', fontSize: '0.3rem' }}>
          Want to explore Web3 world? <br /> You can learn everything in Dplus Arena Academy!
        </WhiteText>
        <Stack direction={'row'} spacing={50} mt={23}>
          <MenuImageButton onClick={() => scrollToElement('next')}>BEGINNER</MenuImageButton>
          <MenuImageButton onClick={() => scrollToElement('next')}>ADVANCED</MenuImageButton>
        </Stack>
        <WhiteText mt={'5.2rem'} sx={{ fontSize: '0.6rem', alignSelf: 'flex-start', marginLeft: 'calc(6%)' }}>
          TODAY’S QUIZ
        </WhiteText>
        <DividerLine sx={{ mt: '-0.1rem' }} />
        <WhiteText fontSize={'0.8rem'} mt={'0.8rem'}>
          What is Transaction Hash?
        </WhiteText>
        <DividerLine mt={'0.6rem'} />
        <Grid container spacing={50} mt={'0.9rem'} width={'88%'} id="next">
          {CardList.map((data, index) => {
            return (
              <Grid key={index} item xs={6}>
                <OnboardingCard isBeginner={data.isBeginner} title={data.title} pic={data.pic} />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Wrapper>
  )
}
