import { Box, MenuItem, Stack, styled, Typography } from '@mui/material'
// import Background from 'components/Background'
import Input from 'components/Input'
import { MenuImageButton } from 'components/SecondaryMenu'
import { Context, Title } from 'pages/lounge/DWGLounge'
import { useState } from 'react'
import bg_10 from '../../assets/images/lounge/bg_10.png'
import { communityBtnStyle } from './MyCommunity'
import Checkbox from 'components/Checkbox'
import Select from 'components/Select/Select'
import { ReactComponent as CurrencyLogo } from 'assets/svg/eth_logo.svg'
import LogoText from 'components/LogoText'

const StyledGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '2rem 1fr',
  gap: '0.7rem'
})

const StyledTitle = styled(Typography)({
  fontSize: '0.25rem',
  fontFamily: 'SCDreamMedium',
  fontWeight: 'bold'
})

const StyleFlexWrap = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  '&>label': {
    marginRight: '0.6rem',
    marginBottom: '0.3rem'
  }
})

export default function MakeEQuest() {
  const [curPage, setCurPage] = useState(0)
  return (
    <Context
      sx={{
        minHeight: '100vh',
        backgroundImage: `url(${bg_10})`,
        backgroundSize: '100% auto'
      }}
    >
      {/* <Background src={bg_10} /> */}
      <Box
        sx={{
          width: '100%',
          top: '1rem',
          padding: '0 1rem',
          position: 'absolute'
        }}
      >
        <Title>Equest CREATION</Title>
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ height: 0, border: '0.5px solid #30F9C0' }} />
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: '-0.23rem'
            }}
          >
            <Box sx={communityBtnStyle}>{curPage + 1}/3</Box>
          </Box>
        </Box>

        {curPage === 0 && (
          <Stack spacing="0.3rem" mt="0.5rem">
            <Input value={''} label="Title of Equest" />
            <Input value={''} label="Equest Description" rows={4} multiline />
            <Stack direction={'row'} spacing="0.3rem">
              <Box display={'flex'} alignItems="center">
                <Typography fontSize={'0.25rem'} mr="0.2rem" fontWeight="bold">
                  Logo Image
                </Typography>
                <label style={communityBtnStyle}>
                  <input type="file" style={{ width: 0, height: 0 }} />
                  Upload
                </label>
              </Box>
              <Box display={'flex'} alignItems="center">
                <Typography fontSize={'0.25rem'} mr="0.2rem" fontWeight="bold">
                  Starting Day
                </Typography>
                <Box style={communityBtnStyle}>Select</Box>
              </Box>
              <Box display={'flex'} alignItems="center">
                <Typography fontSize={'0.25rem'} mr="0.2rem" fontWeight="bold">
                  Ending day
                </Typography>
                <Box style={communityBtnStyle}>Select</Box>
              </Box>
            </Stack>
            <Box display={'flex'} justifyContent="center" pt="0.5rem">
              <MenuImageButton white onClick={() => setCurPage(1)}>
                NEXT
              </MenuImageButton>
            </Box>
          </Stack>
        )}

        {curPage === 1 && (
          <Stack spacing="0.3rem" mt="0.5rem">
            <StyledGrid>
              <StyledTitle>TWITTER</StyledTitle>
              <StyleFlexWrap>
                <Checkbox label="Follow on Twitter" />
                <Checkbox label="Retweet on Twitter" />
                <Checkbox label="Quote Tweet on Twitter" />
                <Checkbox label="Like a Tweet" />
                <Checkbox label="Refer friends to increase the chance of winning" />
              </StyleFlexWrap>
            </StyledGrid>
            <StyledGrid>
              <StyledTitle>DISCORD</StyledTitle>
              <StyleFlexWrap>
                <Checkbox label="Join a Discord Server" />
              </StyleFlexWrap>
            </StyledGrid>
            <StyledGrid>
              <StyledTitle>TELEGRAM</StyledTitle>
              <StyleFlexWrap>
                <Checkbox label="Join a Telegram Server" />
              </StyleFlexWrap>
            </StyledGrid>

            <StyledGrid>
              <StyledTitle>YOUTUBE</StyledTitle>
              <StyleFlexWrap>
                <Checkbox label="Visit a channel on Youtube" />
                <Checkbox label="Watch a video on Youtube" />
                <Checkbox label="Subscribe a channel on Youtube" />
              </StyleFlexWrap>
            </StyledGrid>
            <StyledGrid>
              <StyledTitle>VISITED A PAGE OR WEBSITE</StyledTitle>
              <StyleFlexWrap>
                <Input value="" />
              </StyleFlexWrap>
            </StyledGrid>
            <StyledGrid>
              <StyledTitle>SUBMIT</StyledTitle>
              <StyleFlexWrap>
                <Checkbox label="E-mail" />
                <Checkbox label="Crypto wallet address" />
                <Checkbox label="The right answer to the pre-set question" />
              </StyleFlexWrap>
            </StyledGrid>

            <Stack direction={'row'} justifyContent="center" spacing="0.4rem" pt="0.3rem">
              <MenuImageButton onClick={() => setCurPage(0)}>PREVIOUS</MenuImageButton>
              <MenuImageButton white onClick={() => setCurPage(2)}>
                NEXT
              </MenuImageButton>
            </Stack>
          </Stack>
        )}

        {curPage === 2 && (
          <Box width="80%" margin="1.5rem auto 0">
            <Box display={'grid'} gridTemplateColumns="5rem 7rem" gap="0.4rem">
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0.3rem" alignItems={'center'}>
                <StyledTitle>REWARD</StyledTitle>
                <Select>
                  <MenuItem
                    sx={{
                      backgroundColor: '#fff'
                    }}
                  >
                    <LogoText logo={<CurrencyLogo />} text="ETH" />
                  </MenuItem>
                </Select>

                <StyledTitle>REWARD DETAIL</StyledTitle>
                <Select>
                  <MenuItem
                    sx={{
                      backgroundColor: '#fff'
                    }}
                  >
                    <LogoText logo={<CurrencyLogo />} text="ETH" />
                  </MenuItem>
                </Select>
                <span />
                <Input value="" endAdornment="amount" />
              </Box>
              <Stack>
                <StyledTitle>DISTRIBUTION RULE</StyledTitle>
                <Box mt="0.3rem" display="grid" gridTemplateColumns="2fr 1.2fr" gap="0.3rem">
                  <Checkbox label="First Come, First-served basis" />
                  <Input value="" endAdornment="People" />
                  <Checkbox label="Lucky Draw" />
                  <Input value="" endAdornment="People" />
                  <Checkbox label="Distributed in equal amount to all participant after the end of the period" />
                </Box>
              </Stack>
            </Box>

            <Stack direction={'row'} justifyContent="center" spacing="0.4rem" pt="0.8rem">
              <MenuImageButton onClick={() => setCurPage(1)}>PREVIOUS</MenuImageButton>
              <MenuImageButton white onClick={() => setCurPage(2)}>
                SUBMIT
              </MenuImageButton>
            </Stack>
          </Box>
        )}
      </Box>
    </Context>
  )
}
