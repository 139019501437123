import { Box, styled, Typography } from '@mui/material'
import Background from 'components/Background'
import SecondaryMenu from 'components/SecondaryMenu'
import { routes } from 'constants/routes'
import { Context, Title } from 'pages/lounge/DWGLounge'
import { useHistory } from 'react-router-dom'
import bg_7 from '../../assets/images/lounge/bg_7.png'
import imga from 'assets/images/lounge/a.png'
import imgb from 'assets/images/lounge/b.png'
import imgc from 'assets/images/lounge/c.png'
import imgd from 'assets/images/lounge/d.png'
import imge from 'assets/images/lounge/e.png'
import imgf from 'assets/images/lounge/f.png'
import imgg from 'assets/images/lounge/g.png'
import b1 from 'assets/images/lounge/b1.png'
import b2 from 'assets/images/lounge/b2.jpg'
import b3 from 'assets/images/lounge/b3.png'
import b4 from 'assets/images/lounge/b4.png'
import b5 from 'assets/images/lounge/b5.png'
import b6 from 'assets/images/lounge/b6.png'
import bg_8 from 'assets/images/lounge/bg_8.jpg'
import bg_9 from 'assets/images/lounge/bg_9.jpg'
import { useEffect, useState } from 'react'
import { ReactComponent as PrveIcon } from 'assets/images/lounge/prve_icon.svg'
import { ReactComponent as NextIcon } from 'assets/images/lounge/next_icon.svg'
import Image from 'components/Image'
import SimpleFooter from 'components/Footer/SimpleFooter'

export const SwiperBox = styled(Box)`
  position: relative;

  .prve,
  .next {
    position: absolute;
    top: 45%;
    left: 0;
    cursor: pointer;
  }

  .next {
    left: unset;
    right: 0;
  }

  .swiper-pagination-bullet {
    border: 1px solid #1da1f2;
  }

  .swiper-pagination-bullet-active {
    background: #1da1f2;
  }

  img:not([draggable]),
  embed,
  object,
  video {
    max-width: 100%;
    height: auto;
  }

  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }

  .swiper-container-no-flexbox .swiper-slide {
    float: left;
  }

  .swiper-container-vertical > .swiper-wrapper {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    box-sizing: content-box;
  }

  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .swiper-container-multirow > .swiper-wrapper {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .swiper-container-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto;
  }

  .swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .swiper-container-autoheight,
  .swiper-container-autoheight .swiper-slide {
    height: auto;
  }

  .swiper-container-autoheight .swiper-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transition-property: -webkit-transform, height;
    -webkit-transition-property: height, -webkit-transform;
    transition-property: height, -webkit-transform;
    transition-property: transform, height;
    transition-property: transform, height, -webkit-transform;
  }

  .swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
  }

  .swiper-wp8-horizontal {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }

  .swiper-wp8-vertical {
    -ms-touch-action: pan-x;
    touch-action: pan-x;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    left: 10px;
    right: auto;
  }

  .swiper-button-prev.swiper-button-black,
  .swiper-container-rtl .swiper-button-next.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
  }

  .swiper-button-prev.swiper-button-white,
  .swiper-container-rtl .swiper-button-next.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    right: 10px;
    left: auto;
  }

  .swiper-button-next.swiper-button-black,
  .swiper-container-rtl .swiper-button-prev.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
  }

  .swiper-button-next.swiper-button-white,
  .swiper-container-rtl .swiper-button-prev.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  }

  .swiper-pagination {
    position: absolute;
    text-align: center;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;
  }

  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
  }

  button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }

  .swiper-pagination-white .swiper-pagination-bullet {
    background: #fff;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #007aff;
  }

  .swiper-pagination-white .swiper-pagination-bullet-active {
    background: #fff;
  }

  .swiper-pagination-black .swiper-pagination-bullet-active {
    background: #000;
  }

  .swiper-container-vertical > .swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }

  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 5px 0;
    display: block;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
  }

  .swiper-pagination-progress {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
  }

  .swiper-pagination-progress .swiper-pagination-progressbar {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }

  .swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
    -webkit-transform-origin: right top;
    transform-origin: right top;
  }

  .swiper-container-horizontal > .swiper-pagination-progress {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
  }

  .swiper-container-vertical > .swiper-pagination-progress {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
  }

  .swiper-pagination-progress.swiper-pagination-white {
    background: rgba(255, 255, 255, 0.5);
  }

  .swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
    background: #fff;
  }

  .swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
    background: #000;
  }

  .swiper-container-3d {
    -webkit-perspective: 1200px;
    -o-perspective: 1200px;
    perspective: 1200px;
  }

  .swiper-container-3d .swiper-cube-shadow,
  .swiper-container-3d .swiper-slide,
  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-wrapper {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  }

  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }

  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }

  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }

  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }

  .swiper-container-coverflow .swiper-wrapper,
  .swiper-container-flip .swiper-wrapper {
    -ms-perspective: 1200px;
  }

  .swiper-container-cube,
  .swiper-container-flip {
    overflow: visible;
  }

  .swiper-container-cube .swiper-slide,
  .swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
  }

  .swiper-container-cube .swiper-slide .swiper-slide,
  .swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none;
  }

  .swiper-container-cube .swiper-slide-active,
  .swiper-container-cube .swiper-slide-active .swiper-slide-active,
  .swiper-container-flip .swiper-slide-active,
  .swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
  }

  .swiper-container-cube .swiper-slide-shadow-bottom,
  .swiper-container-cube .swiper-slide-shadow-left,
  .swiper-container-cube .swiper-slide-shadow-right,
  .swiper-container-cube .swiper-slide-shadow-top,
  .swiper-container-flip .swiper-slide-shadow-bottom,
  .swiper-container-flip .swiper-slide-shadow-left,
  .swiper-container-flip .swiper-slide-shadow-right,
  .swiper-container-flip .swiper-slide-shadow-top {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .swiper-container-cube .swiper-slide {
    visibility: hidden;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
  }

  .swiper-container-cube.swiper-container-rtl .swiper-slide {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }

  .swiper-container-cube .swiper-slide-active,
  .swiper-container-cube .swiper-slide-next,
  .swiper-container-cube .swiper-slide-next + .swiper-slide,
  .swiper-container-cube .swiper-slide-prev {
    pointer-events: auto;
    visibility: visible;
  }

  .swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: 0;
  }

  .swiper-container-fade.swiper-container-free-mode .swiper-slide {
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  .swiper-container-fade .swiper-slide {
    pointer-events: none;
    -webkit-transition-property: opacity;
    transition-property: opacity;
  }

  .swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none;
  }

  .swiper-container-fade .swiper-slide-active,
  .swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
  }

  .swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .swiper-zoom-container > canvas,
  .swiper-zoom-container > img,
  .swiper-zoom-container > svg {
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, 0.1);
  }

  .swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%;
  }

  .swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%;
  }

  .swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
  }

  .swiper-scrollbar-cursor-drag {
    cursor: move;
  }

  .swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    animation: swiper-preloader-spin 1s steps(12, end) infinite;
  }

  .swiper-lazy-preloader:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .swiper-lazy-preloader-white:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  }

  @-webkit-keyframes swiper-preloader-spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes swiper-preloader-spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .swiper-container.one {
    padding-top: 40px;
    text-align: center;
  }

  .swiper-container.one .swiper-slide {
    padding: 0 43px;
  }

  .swiper-container {
    width: 100%;
    padding-bottom: 60px;
  }

  .swiper-slide img {
    display: block;
    margin: auto;
    width: 100%;
  }

  .swiper-slide {
    width: 390px;
    height: auto;
    padding: 0 15px;
  }

  .two .swiper-slide {
    width: 5rem;
  }

  .swiper-slide img {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }

  .swiper-slide .slider-image .preview-icon {
    z-index: -1;
    width: calc(100% - 30px);
  }

  .swiper-slide.swiper-slide-active .slider-image:hover .preview-icon {
    opacity: 1;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    z-index: 1;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #fff;
    border-radius: 10px;
    display: inline-block;
    height: 10px;
    opacity: 1;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    width: 26px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }

  .swiper-pagination-bullet-active {
    background: rgba(0, 0, 0, 0) -webkit-linear-gradient(left, #31b047 0%, #fff 100%) repeat scroll 0 0;
    /* background: rgba(0, 0, 0, 0) linear-gradient(to right, #ee0f6f 0%, #f89482 100%) repeat scroll 0 0; */
    border: medium none;
    height: 12px;
    opacity: 1;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    width: 12px;
  }
`

const lists = [
  { img: imga, name: 'NAME' },
  { img: imgb, name: 'NAME' },
  { img: imgc, name: 'NAME' },
  { img: imgd, name: 'NAME' },
  { img: imge, name: 'NAME' },
  { img: imgf, name: 'NAME' },
  { img: imgg, name: 'NAME' }
]

export default function QuestLounge() {
  const history = useHistory()
  const [swiper, setSwiper] = useState<any>(null)

  useEffect(() => {
    const _s = new window.Swiper('.swiper-container.two', {
      pagination: '.swiper-pagination',
      paginationClickable: true,
      effect: 'coverflow',
      loop: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      coverflow: {
        rotate: 0,
        stretch: 100,
        depth: 150,
        modifier: 1.5,
        slideShadows: false
      },
      onInit: function(s: any) {
        setTimeout(s.slideNext, 1000)
      }
    })
    setSwiper(_s)
  }, [])

  const list1 = [
    { img: b1, name: '#NAME' },
    { img: b2, name: '#NAME' },
    { img: b3, name: '#NAME' }
  ]
  const list2 = [
    { img: b4, name: '#NAME' },
    { img: b5, name: '#NAME' },
    { img: b6, name: '#NAME' }
  ]

  return (
    <Box>
      <Context>
        <Background src={bg_7} />
        <Box
          sx={{
            width: '100%',
            top: '1rem',
            padding: '0 1rem',
            position: 'absolute'
          }}
        >
          <Title>TOP CAMPAIGNS</Title>
          <SecondaryMenu
            list={[
              { text: 'MY COMMUNITY', event: () => history.push(routes.questLoungeMyCommunity) },
              { text: 'MAKE A EQUEST', event: () => history.push(routes.questLoungeMakeEQuest) }
            ]}
          />
          <SwiperBox mt="1.5rem" marginTop="1rem" padding="0 75px">
            <div className="swiper-container two">
              <div className="swiper-wrapper">
                {lists.map((item, index) => (
                  <div className="swiper-slide" key={index}>
                    <div className="slider-image">
                      <img src={item.img} alt="" />
                    </div>
                  </div>
                ))}
              </div>
              <div className="swiper-pagination"></div>
            </div>
            <Box>
              <PrveIcon className="prve" onClick={() => swiper?.slidePrev()} />
              <NextIcon className="next" onClick={() => swiper?.slideNext()} />
            </Box>
          </SwiperBox>
        </Box>
      </Context>

      <Context>
        <Background src={bg_8} />
        <Box
          sx={{
            width: '100%',
            top: '1rem',
            padding: '0 1rem',
            position: 'absolute'
          }}
        >
          <Title>TRENDING NOW</Title>
          <SecondaryMenu
            list={[{ text: 'ALL CAMPAIGNS', event: () => history.push(routes.questLoungeAllCampaigns) }]}
          />
          <Box
            mt="1.2rem"
            padding="0 0.8rem"
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {list1.map((item, index) => (
              <ShowItem key={index} {...item} />
            ))}
          </Box>
        </Box>
      </Context>
      <Context>
        <Background src={bg_9} />
        <Box
          sx={{
            width: '100%',
            top: '1rem',
            padding: '0 1rem',
            position: 'absolute'
          }}
        >
          <Title>HOT COMMUNITY</Title>
          <SecondaryMenu
            list={[{ text: 'ALL COMMUNITIES', event: () => history.push(routes.questLoungeAllCampaigns) }]}
          />
          <Box
            mt="1.2rem"
            padding="0 0.8rem"
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {list2.map((item, index) => (
              <ShowItem key={index} {...item} />
            ))}
          </Box>
        </Box>
      </Context>

      <SimpleFooter />
    </Box>
  )
}

function ShowItem({ img, name }: { img: string; name: string }) {
  const history = useHistory()
  return (
    <Box
      sx={{
        display: 'grid',
        justifyItems: 'center'
      }}
    >
      <Image
        onClick={() => history.push(routes.questLoungeCampaign)}
        src={img}
        style={{
          width: '5rem',
          height: '5rem',
          cursor: 'pointer'
        }}
      />
      <Typography mt="0.4rem" fontSize={'0.4rem'} fontWeight={500} fontFamily={'SCDreamMedium'}>
        {name}
      </Typography>
    </Box>
  )
}
