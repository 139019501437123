import { Box, Stack, styled, Typography } from '@mui/material'
import Bg from 'assets/images/web3-main-bg.png'
import { MenuImageButton } from '../../components/SecondaryMenu'
import { useHistory } from 'react-router-dom'
import { routes } from '../../constants/routes'

const WhiteLineBox = styled(Box)`
  border: 2px solid #ffffff;
  background: rgba(98, 98, 98, 0.8);
  display: flex;
  flex-direction: column;
  height: 7.1rem;
  align-items: center;
  position: relative;
  padding-bottom: 0.3rem;
`
const TitleBox = styled(Box)`
  border-bottom: 2px solid #ffffff;
  height: 3rem;
  display: flex;
  padding: 0.4rem;
`

const Title = styled(Typography)`
  text-align: center;
  font-size: 0.6rem;
  font-family: 'SCDreamHeavy', serif;
  font-weight: 600;
`
const Content = styled(Typography)`
  color: white;
  text-align: center;
  font-size: 0.25rem;
  margin: 0.5rem 0;
`
export default function Web3Academy() {
  const history = useHistory()
  return (
    <Box
      position={'relative'}
      sx={{
        minHeight: '100vh',
        backgroundImage: `url(${Bg})`,
        backgroundSize: '100% auto'
      }}
      width={'100%'}
      display={'flex'}
    >
      <Stack
        direction={'row'}
        spacing={'0.4rem'}
        position={'absolute'}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <WhiteLineBox>
          <TitleBox>
            <Title color={'#9CF8EE'} margin={'auto'}>
              WEB3
              <br /> ONBOARDING <br />
              ACADEMY
            </Title>
          </TitleBox>
          <Content>
            Don’t know where
            <br /> to start learning Web3?
            <br /> Start learning in <br />
            Dplus Arena Academy & Get points
          </Content>
          <MenuImageButton
            sx={{ position: 'absolute', bottom: '0.3rem' }}
            onClick={() => {
              history.push(routes.onboarding)
            }}
          >
            READ MORE
          </MenuImageButton>
        </WhiteLineBox>
        <WhiteLineBox>
          <TitleBox>
            <Title color={'#FFFFFF'} margin={'auto'}>
              WEB3 CAREER
              <br /> ACADEMY
            </Title>
          </TitleBox>
          <Content>
            Want to be a part of Web3? <br /> Start now at Dplus Arena Academy
          </Content>
          <MenuImageButton
            onClick={() => {
              history.push(routes.career_academy)
            }}
          >
            GAMER
          </MenuImageButton>
          <MenuImageButton
            mt={10}
            onClick={() => {
              history.push(routes.career_academy)
            }}
          >
            GENERAL
          </MenuImageButton>
        </WhiteLineBox>
        <WhiteLineBox>
          <TitleBox>
            <Title color={'#B8FDB3'} margin={'auto'}>
              WEB3 CAREER
              <br /> JOB BOARD
            </Title>
          </TitleBox>
          <Content>Hire & Seek Web3 jobs here!</Content>
          <MenuImageButton
            onClick={() => {
              history.push(routes.job_board)
            }}
            sx={{ position: 'absolute', bottom: '0.3rem' }}
          >
            READ MORE
          </MenuImageButton>
        </WhiteLineBox>
      </Stack>
    </Box>
  )
}
