import { Box, Stack, styled, Typography } from '@mui/material'
import Background from 'components/Background'
import { Context, Title } from './DWGLounge'
import bg1 from '../../assets/images/lol/bg_2.png'
import bg_3 from '../../assets/images/lol/bg_3.png'
import bg_5 from '../../assets/images/lol/bg_5.png'
import image_5 from '../../assets/images/lol/image_5.png'
import Divider from 'components/Divider'
import Image, { GalleryImage } from 'components/Image'
import SecondaryMenu, { MenuImageButton } from 'components/SecondaryMenu'
import teamPic0 from 'assets/images/lol/e-sport-1.png'
import teamPic1 from 'assets/images/lol/e-sport-2.png'
import teamPic2 from 'assets/images/lol/e-sport-3.png'
import teamPic3 from 'assets/images/lol/e-sport-4.png'
import MaskVideo from 'assets/images/lol/MaskVideo.png'
import SimpleFooter from 'components/Footer/SimpleFooter'
import MembersSwiper from './MembersSwiper'
import { useHistory } from 'react-router-dom'
import { routes } from 'constants/routes'
import Fan1 from 'assets/images/lol/fan-galleries1.jpg'
import Fan2 from 'assets/images/lol/fan-galleries2.jpg'
import Fan3 from 'assets/images/lol/fan-galleries3.jpg'
import Fan4 from 'assets/images/lol/fan-galleries4.jpg'

const EllipsisText = styled(Typography)(({ height, line }: { height: string; line: number }) => ({
  height: height,
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': `${line}`,
  wordBreak: 'break-all',
  overflowY: 'hidden'
}))

export default function LoLLounge() {
  const history = useHistory()
  return (
    <Box>
      <Context>
        <Background src={bg1} />
        <Box
          sx={{
            width: '100%',
            top: '0.5rem',
            padding: '0 1.26rem',
            position: 'absolute'
          }}
        >
          <Title>#DPLUS ARENA LOUNGE LOL TEAM</Title>
          <Divider />
          <Typography mt="0.25rem" fontSize="0.4rem">
            Introduction
          </Typography>
          <EllipsisText mt="0.25rem" fontSize="0.25rem" line={4} height="1.2rem">
            Description Sample Text… Description Sample Text… Description Sample Text… Description Sample Text…
            Description Sample Text… Description Sample Text… Description Sample Text… Description Sample Text…
            Description Sample Text… Description Sample Text… Description Sample Text… Description Sample Text…
            Description Sample Text… Description Sample Text… Description Sample Text… Description Sample Text…
            Description Sample Text… Description Sample Text… Description Sample Text… Description Sample Text…
          </EllipsisText>

          <Box mt={'0.8rem'}>
            <Title>TEAM PICTURE</Title>
            <SecondaryMenu list={[{ text: 'SEE ALL' }]} />
            <Box
              onClick={() => history.push(routes.DWGTeamPic)}
              mt={'0.8rem'}
              sx={{
                whiteSpace: 'nowrap',
                width: '100%',
                overflowX: 'auto',
                '&>*': {
                  marginRight: '0.25rem'
                }
              }}
            >
              <Image src={teamPic0} />
              <Image src={teamPic1} />
              <Image src={teamPic2} />
              <Image src={teamPic3} />
            </Box>
          </Box>
        </Box>
      </Context>

      <Context>
        <Background src={image_5} />
        <Box
          sx={{
            width: '100%',
            top: '1rem',
            padding: '0 1.26rem',
            position: 'absolute'
          }}
        >
          <Title>TEAM VIDEO</Title>
          <SecondaryMenu list={[{ text: 'SEE ALL' }]} />

          <Box mt="1rem">
            <img style={{ width: '13rem', display: 'block', margin: 'auto' }} src={MaskVideo} alt={''} />
          </Box>
        </Box>
      </Context>

      <Context>
        <Background src={bg_5} />
        <Box
          sx={{
            width: '100%',
            top: '1rem',
            padding: '0 1.26rem',
            position: 'absolute'
          }}
        >
          <Title>MEMBERS</Title>
          <SecondaryMenu list={[]} />
          <MembersSwiper />
        </Box>
      </Context>

      <Context>
        <Background src={bg_3} />
        <Box
          sx={{
            width: '100%',
            top: '1rem',
            padding: '0 2.1rem',
            position: 'absolute'
          }}
        >
          <Box display={'grid'} gridTemplateColumns="1fr 6.8rem" gap="1.2rem">
            <Box>
              <Typography fontSize={'0.5rem'} fontWeight="bold" mb={10}>
                UPDATES BOARD
              </Typography>
              <Stack
                sx={{
                  height: '6.8rem',
                  overflowY: 'auto'
                }}
                spacing="0.22rem"
              >
                {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                  <Box
                    key={item}
                    sx={{
                      backgroundColor: '#fff',
                      padding: '0.2rem 0.2rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography color={'#000'} fontSize="0.3rem" fontFamily={'SCDreamMedium'}>
                      Board Title
                    </Typography>
                    <Typography color={'#000'}>2023.01.10</Typography>
                  </Box>
                ))}
              </Stack>
              <Box display={'flex'} justifyContent="center" mt={20}>
                <MenuImageButton>SEE ALL</MenuImageButton>
              </Box>
            </Box>
            <Box>
              <Typography fontSize={'0.5rem'} fontWeight="bold" mb={10} fontFamily={'SCDreamExtraBold'}>
                FAN GALLERIES
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  gap: '0.2rem'
                }}
              >
                {[Fan1, Fan2, Fan3, Fan4].map(v => (
                  <GalleryImage width="3.3rem" height="3.3rem" key={v} src={v} />
                ))}
              </Box>
              <Box display={'flex'} justifyContent="center" mt={20}>
                <MenuImageButton>SEE ALL</MenuImageButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Context>
      <SimpleFooter />
    </Box>
  )
}
