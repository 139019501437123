import { Box, Button, styled, Typography } from '@mui/material'
import Divider from '../../components/Divider'
import { MenuImageButton } from '../../components/SecondaryMenu'
import Bg from '../../assets/images/bg_community_setting.jpg'

const Title = styled(Typography)`
  color: white;
  font-size: 0.4rem;
  font-family: 'SCDreamMedium', serif;
  margin-top: 0.5rem;
`
const Content = styled(Typography)`
  color: white;
  font-size: 0.25rem;
  margin-top: 0.3rem;
`
export const GreenButton = styled(Button)`
  color: black;
  background: #fff;
  height: 0.5rem;
  min-width: 1.5rem;
  border-radius: 0.26rem;
  font-size: 0.25rem;
  font-family: 'SCDreamMedium', serif;

  &:hover {
    background: #30f9c0;
  }
`
const InfoRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.3rem;
`

export default function CommunitySetting() {
  return (
    <Box
      style={{
        background: `url(${Bg})`,
        display: 'flex',
        width: '100%',
        backgroundRepeat: 'repeat',
        flexDirection: 'column',
        padding: '120px'
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box style={{ width: '100%' }}>
          <Typography fontSize={'60px'} fontFamily={'SCDreamExtraBold'}>
            MY COMMUNITY SETTING
          </Typography>
          <Divider />
        </Box>
        <Box mt={30}>
          <MenuImageButton>SAVE</MenuImageButton>
        </Box>
      </Box>
      <Title>COMMUNITY NAME</Title>
      <Divider />
      <Content>
        Description Sample Text…Description Sample Text…Description Sample Text…Description Sample Text…
      </Content>
      <Title>INTRODUCTION</Title>
      <Divider />
      <Content>
        Description Sample Text…Description Sample Text…Description Sample Text…Description Sample Text…
      </Content>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          gridTemplateColumns: '1fr 1.3fr',
          gap: '20px'
        }}
      >
        <Box>
          <Title>LOGO IMAGE</Title>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              marginTop: '0.43rem'
            }}
          >
            <Box
              sx={{
                width: '2.5rem',
                aspectRatio: '1/1',
                background: 'white'
              }}
            />
            <GreenButton style={{ marginLeft: '0.1rem' }}>CHANGE</GreenButton>
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
            <Title>COMMUNITY URL</Title>
            <Typography fontSize={'0.25rem'} fontFamily={'SCDreamLight'}>
              (IF CANNOT BE CHANGED AFTER CREATION)
            </Typography>
          </Box>
          <Divider />
          <Content>
            Description Sample Text… <br />
            Description Sample Text… <br />
            Description Sample Text… <br />
            Description Sample Text…
          </Content>
          <GreenButton style={{ marginTop: '0.9rem' }}>CHANGE</GreenButton>
        </Box>
      </Box>
      <Title>WEBSITE URL</Title>
      <Divider />
      <InfoRow>
        <Content sx={{ marginTop: '0px' }}>Description Sample Text…Description Sample Text…</Content>
        <GreenButton>CHANGE</GreenButton>
      </InfoRow>
      <Title>TWITTER</Title>
      <Divider />
      <InfoRow>
        <Content sx={{ marginTop: '0px' }}>@Description Sample Text…Description Sample Text…</Content>
        <GreenButton>CHANGE</GreenButton>
      </InfoRow>
      <Title>DISCORD</Title>
      <Divider />
      <InfoRow>
        <Content sx={{ marginTop: '0px' }}>@Description Sample Text…Description Sample Text…</Content>
        <GreenButton>CHANGE</GreenButton>
      </InfoRow>
      <Title>TELEGRAM</Title>
      <Divider />
      <InfoRow
        sx={{
          marginBottom: '120px'
        }}
      >
        <Content sx={{ marginTop: '0px' }}>Description Sample Text…Description Sample Text…</Content>
        <GreenButton>CHANGE</GreenButton>
      </InfoRow>
    </Box>
  )
}
